import { i18n } from '@anschuetz-elog/frontend-core';
import Vue from 'vue';

import { clearDatabases } from '@/lib/offline/databases';
import useFeathers from '#/compositions/useFeathers';
import { useClientDeviceStore } from '#/stores/clientDevice';

export function firstToUpperCase(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export async function resetApp(): Promise<void> {
  let dialog;
  try {
    // open dialog and ask user to proceed
    dialog = await Vue.dialog.confirm(i18n.t('about.confirm_reset_app') as string, {
      loader: true,
      customClass: 'dialog',
      okText: 'Confirm',
      cancelText: 'Cancel',
    });
  } catch (err) {
    // Clicked on cancel, so we do nothing
    return;
  }

  const clientDeviceStore = useClientDeviceStore();
  const feathers = useFeathers();
  try {
    const { clientDeviceId } = clientDeviceStore;
    if (clientDeviceId) {
      try {
        await feathers.service('clientDevice').remove(clientDeviceId);
      } catch (error) {
        // no need to do something, since we want to reset it anyway
      }
    }

    // empty caches
    if ('caches' in window) {
      await Promise.all((await window.caches.keys()).map((cacheName) => window.caches.delete(cacheName)));
    }

    // unregister service-worker
    if ('serviceWorker' in navigator) {
      await Promise.all(
        (await navigator.serviceWorker.getRegistrations()).map((registration) => registration.unregister()),
      );
    }

    // clear pouchDbs
    await clearDatabases();

    // clear localStorage
    if ('localStorage' in window) {
      window.localStorage.clear();
    }

    // clear sessionStorage
    if ('sessionStorage' in window) {
      window.sessionStorage.clear();
    }

    dialog.close();

    // reload page from server
    window.location.reload();
  } catch (error) {
    try {
      // open dialog and ask user to proceed
      dialog.close();

      dialog = await Vue.dialog.confirm(i18n.t('about.reset_app_error') as string, {
        loader: true,
        customClass: 'dialog',
        okText: i18n.t('general.try_again') as string,
        cancelText: i18n.t('general.close') as string,
      });

      void resetApp();
    } catch (err) {
      // Clicked on cancel, so we do nothing
      return;
    } finally {
      dialog.close();
    }
  }
}
