import { ALL_RECORDS_PSEUDO_ID, AUTH_RESOURCES, Book } from '@anschuetz-elog/common';
import { AnyAbility } from '@casl/ability';
import { computed, getCurrentInstance, Ref, ref, watch } from 'vue';
import { Location } from 'vue-router';

import { getPages } from '#/config/pages';
import { getBooksForUser } from '#/helpers/AuthorizedBooksForUser';
import i18n from '#/i18n';
import { IconName } from '#/icons';
import { useAuthStore } from '#/stores/auth';

import useFind from './useFind';

export type MenuItem = {
  id: string;
  text: string;
  icon: { name: IconName; custom?: undefined } | { name?: undefined; custom: string };
  hidden?: boolean;
  action: Location | (() => void);
  items?: undefined;
  outlined?: boolean;
  color?: string;
};

export type SubMenuGroup = {
  title: string;
  icon: IconName;
  items: MenuItem[];
  hidden?: boolean;
};

export type MenuGroup = {
  color: string;
  title: string;
  icon: IconName;
  items: (MenuItem | SubMenuGroup)[];
  hidden?: boolean;
};

export default function usePages(): { menu: Ref<MenuGroup[]> } {
  const vm = getCurrentInstance();

  const authStore = useAuthStore();
  const isAuthenticated = computed(() => {
    return authStore.isAuthenticated;
  });

  const user = computed(() => authStore.user);

  const ability = ref<AnyAbility | undefined>(vm?.proxy?.$ability);

  if (vm) {
    watch(vm.proxy.$ability, () => (ability.value = vm.proxy.$ability));
  }

  const baseMenu = computed<MenuGroup[]>(() => {
    if (!user.value || !ability.value) {
      return [];
    }
    return getPages(ability.value as AnyAbility).map((page) => ({
      ...page,
      items: page.buttons.map((button) => ({
        ...button,
        icon: { name: button.icon },
      })),
    }));
  });

  const { data: arrangements } = useFind(
    'book-arrangement',
    computed(() => (isAuthenticated.value ? {} : undefined)),
  );

  const arrangement = computed(() => {
    if (!arrangements.value) {
      return;
    } else {
      return arrangements.value[0];
    }
  });
  const authorizedBooks = getBooksForUser();

  const { data: views } = useFind(
    'view',
    computed(() =>
      !isAuthenticated.value
        ? undefined
        : {
            query: {
              $or: [
                { 'book._id': { $in: authorizedBooks.value.map((book) => book._id) } },
                { book: { $exists: false } },
              ],
            },
          },
    ),
  );

  const logbookMenuGroup = computed<MenuGroup | undefined>(() => {
    const items: MenuGroup['items'] = [
      {
        id: 'addNewRecord',
        text: i18n.tc('protocol.new_protocol'),
        icon: { name: 'circle/plus' },
        hidden: !ability.value?.can('create', AUTH_RESOURCES.PROTOCOL),
        action: { name: 'protocol-new', params: { bookId: ALL_RECORDS_PSEUDO_ID } },
        outlined: true,
      },
      {
        id: 'addNewChecklist',
        text: i18n.tc('checklist.new_checklist'),
        icon: { name: 'circle/plus' },
        hidden: !ability.value?.can('create', AUTH_RESOURCES.PROTOCOL),
        action: { name: 'checklist-new' },
        outlined: true,
        color: 'secondary',
      },
      {
        id: 'allRecords',
        text: i18n.tc('protocol.all_protocols'),
        icon: { name: 'logbook/record' },
        hidden: !ability.value?.can('read', AUTH_RESOURCES.PROTOCOL),
        action: { name: 'protocols', params: { bookId: ALL_RECORDS_PSEUDO_ID } },
      },
    ];
    if (
      arrangement.value === undefined ||
      !arrangement.value.bookGroup.some(
        (group) =>
          group.name !== 'unassigned' &&
          group.books.filter((book) => authorizedBooks.value.map((b) => b._id).includes(book._id)).length > 0,
      )
    ) {
      items.push(
        ...authorizedBooks.value.map<MenuItem>((book) => ({
          id: book.technicalName,
          text: book.name,
          icon: book.icon ? { custom: book.icon } : { name: 'logbook/book' },
          action: { name: 'protocols', params: { bookId: book._id } },
        })),
      );
    } else {
      items.push(
        ...arrangement.value.bookGroup
          .map<SubMenuGroup>((bookGroup) => ({
            title: bookGroup.name === 'unassigned' ? i18n.tc('protocol.unassigned_group') : bookGroup.name,
            icon: 'logbook/book',
            items: bookGroup.books
              .map<Book | undefined>(({ _id: bookId }) => authorizedBooks.value.find((book) => book._id === bookId))
              .filter((book): book is Book => book !== undefined)
              .map<MenuItem>((book) => ({
                id: book.technicalName,
                text: book.name,
                icon: book.icon ? { custom: book.icon } : { name: 'logbook/book' },
                action: { name: 'protocols', params: { bookId: book._id } },
              })),
          }))
          .filter((bookGroup) => bookGroup.items.length > 0),
      );
    }
    return {
      title: i18n.tc('book.log_books'),
      color: 'primary',
      icon: 'logbook/book',
      items,
      hidden: !ability.value?.can('read', AUTH_RESOURCES.PROTOCOL) || authorizedBooks.value.length === 0,
    };
  });

  const viewsMenuGroup = computed<MenuGroup | undefined>(() => {
    return {
      title: i18n.tc('settings.wizard.views'),
      color: 'primary',
      icon: 'eye/on',
      items: [...views.value]
        .sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
        .map<MenuItem>((view) => ({
          id: view.name,
          text: view.name,
          icon: view.icon ? { custom: view.icon } : { name: 'eye/on' },
          action: { name: 'view', params: { viewId: view._id } },
        })),
      hidden: !ability.value?.can('read', AUTH_RESOURCES.PROTOCOL) || views.value.length === 0,
    };
  });

  const { data: reportTypes } = useFind(
    'protocolType',
    computed(() => (!isAuthenticated.value ? undefined : { query: { isReport: true, successor: { $exists: false } } })),
  );
  const protocolsMenuGroup = computed<MenuGroup | undefined>(() => {
    return {
      title: i18n.tc('report.reports'),
      color: 'primary_light',
      icon: 'logbook/report',
      items: reportTypes.value.map((reportType) => ({
        id: reportType._id,
        text: reportType.name,
        icon: { name: 'logbook/report' },
        hidden: !ability.value?.can('read', AUTH_RESOURCES.PROTOCOL),
        action: { name: 'reports', params: { protocolTypeId: reportType._id } },
      })),
    };
  });

  const menu = computed(() => {
    const groups = [...baseMenu.value];
    if (protocolsMenuGroup.value) {
      groups.unshift(protocolsMenuGroup.value);
    }
    if (viewsMenuGroup.value) {
      groups.unshift(viewsMenuGroup.value);
    }
    if (logbookMenuGroup.value) {
      groups.unshift(logbookMenuGroup.value);
    }
    return groups
      .map((group) => ({
        ...group,
        items: group.items.filter((item) => !item.hidden),
      }))
      .filter((group) => !group.hidden && group.items.length > 0);
  });

  return { menu };
}
