import { dynamicConfig } from '@anschuetz-elog/frontend-core';
import debug from 'debug';
import Vue, { watch } from 'vue';

import { useBackendIntegrityCheckStore } from '@/stores/backendIntegrityCheck';
import { useServiceWorkerStore } from '@/stores/serviceWorker';
import { useWebsocketStore } from '#/stores/websocket';

export function load(): void {
  if (dynamicConfig('ONLINE_ONLY')) {
    return;
  }

  Vue.use((): void => {
    const backendIntegrityCheckStore = useBackendIntegrityCheckStore();
    const websocketStore = useWebsocketStore();
    const serviceWorkerStore = useServiceWorkerStore();

    watch(
      () => [websocketStore.isConnected, serviceWorkerStore.updating],
      () => {
        if (websocketStore.isConnected && !serviceWorkerStore.updating) {
          void backendIntegrityCheckStore.checkBackendIntegrity();
        } else {
          backendIntegrityCheckStore.setChecked(false);
        }
      },
    );

    debug('Backend integrity check registered');
  });
}
