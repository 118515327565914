import { Application, Approval, Protocol, SYNC_CHECKPOINT_INTERVAL } from '@anschuetz-elog/common';
import { throttle, ThrottleSettings } from 'lodash';

import { useOfflineStore } from '@/stores/offline';

import { loadDB, OFFLINE_SYNC_META } from './databases';

const APPROVAL_META_ID_PREFIX = 'approval-';
type ApprovalMeta = {
  _id: `${typeof APPROVAL_META_ID_PREFIX}${Approval['_id']}`;
  approvalId: Approval['_id'];
  flagsApplied: boolean;
};

const GATEWAY_AMENDMENT_META_ID_PREFIX = 'gatewayAmendment-';
type GatewayAmendmentMeta = {
  _id: `${typeof GATEWAY_AMENDMENT_META_ID_PREFIX}${Protocol['gatewayAmendmentId']}`;
  protocolId: Protocol['_id'];
};

const db = loadDB<ApprovalMeta | GatewayAmendmentMeta>(OFFLINE_SYNC_META);

function getApprovalMetaId(approval: Approval): ApprovalMeta['_id'] {
  return `${APPROVAL_META_ID_PREFIX}${approval._id}`;
}

function getGatewayAmendmentMetaId(protocol: Protocol | Protocol['gatewayAmendmentId']): GatewayAmendmentMeta['_id'] {
  return `${GATEWAY_AMENDMENT_META_ID_PREFIX}${typeof protocol === 'string' ? protocol : protocol.gatewayAmendmentId}`;
}

/** A throttled function that is called once per given wait time with the array of all collected data from the single calls.
 * The max size of the collected data can be configured and when reaching the limit it immediately runs the function.
 */
function stack<T, R>(
  fn: (data: T[]) => R,
  options: ThrottleSettings & { wait?: number; maxStack?: number } = {},
): { (data: T): R | undefined; flush(): R | undefined } {
  let collectedData: T[] = [];
  const wrappedFn = throttle(
    () => {
      // when finally executing the function we first copy and reset the collected data for future calls...
      const collectedDataCopy = collectedData;
      collectedData = [];
      // ... and execute the function then with this copy of the collected data
      return fn(collectedDataCopy);
    },
    options.wait,
    options,
  );
  const stackedFn = (data: T) => {
    collectedData.push(data);
    const result = wrappedFn();
    if (options.maxStack !== undefined && collectedData.length > options.maxStack) {
      return wrappedFn.flush();
    }
    return result;
  };
  stackedFn.flush = () => wrappedFn.flush();
  return stackedFn;
}

const saveMetaDocuments = stack(
  async (data: (ApprovalMeta | GatewayAmendmentMeta)[]): Promise<void> => {
    await db.bulkDocs(data);
  },
  { wait: 100, maxStack: SYNC_CHECKPOINT_INTERVAL, leading: false },
);

const defaultOptions = { whileSyncPending: false };

/** Listens on all created approvals and protocols at the given feathers application and stores some meta information for synchronisation. */
export function trackSyncMetaData(feathers: Application, options: Partial<typeof defaultOptions> = {}): void {
  const { whileSyncPending } = { ...defaultOptions, ...options };

  const offlineStore = useOfflineStore();

  feathers.service('approval').on('created', (approval: Approval) => {
    if (!whileSyncPending && offlineStore.isSyncPending) {
      return;
    }
    void saveMetaDocuments({ _id: getApprovalMetaId(approval), approvalId: approval._id, flagsApplied: false });
  });

  feathers.service('protocol').on('created', (protocol: Protocol) => {
    if (!whileSyncPending && offlineStore.isSyncPending) {
      return;
    }
    void saveMetaDocuments({ _id: getGatewayAmendmentMetaId(protocol), protocolId: protocol._id });
  });
}

/**
 * Returns the ids all synchronized approvals that have not been applied yet.
 * Not applied means that the approved protocols have not received an update
 * of their approval flags.
 */
export async function loadUnappliedApprovals(): Promise<Approval['_id'][]> {
  await saveMetaDocuments.flush();
  const result = await db.find({ selector: { flagsApplied: false } });
  return result.docs
    .filter((doc): doc is PouchDB.Core.ExistingDocument<ApprovalMeta> => doc._id.startsWith(APPROVAL_META_ID_PREFIX))
    .map((doc) => doc.approvalId);
}

/**
 * Marks the given approval as applied so that it must not be considered anymore in future syncs
 */
export async function markApprovalFlagsAsApplied(approval: Approval): Promise<void> {
  await saveMetaDocuments.flush();
  const approvalMeta = await db.get<ApprovalMeta>(getApprovalMetaId(approval));
  approvalMeta.flagsApplied = true;
  await db.remove(approvalMeta);
}

/**
 * Returns the ids of those protocols with the given gateway amendment ids.
 * This is e.g. used for gateway approvals which are referencing the amendments and not the actual records.
 */
export async function getProtocolIdsByGatewayAmendmentIds(
  gatewayAmendmentIds: Protocol['gatewayAmendmentId'][],
): Promise<Protocol['_id'][]> {
  await saveMetaDocuments.flush();
  const result = await db.allDocs({
    keys: gatewayAmendmentIds.map((id) => getGatewayAmendmentMetaId(id)),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    include_docs: true,
  });
  return result.rows
    .map((row) => row.doc)
    .filter(
      (doc): doc is PouchDB.Core.ExistingDocument<GatewayAmendmentMeta> =>
        doc !== undefined && doc._id.startsWith(GATEWAY_AMENDMENT_META_ID_PREFIX),
    )
    .map((doc) => doc.protocolId);
}
