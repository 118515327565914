import 'windi.css';
import './pages';

import { install as FrontendCorePlugin } from '@anschuetz-elog/frontend-core';
import Vue, { VNode } from 'vue';

import App from '@/App.vue';
import createRouter from '@/router';

import logger from './logger';

logger.info('Loading eLog...');

const vueOptions = {
  createRouter,
};
Vue.use(FrontendCorePlugin, vueOptions);

Vue.config.productionTip = false;

new Vue({
  ...vueOptions,
  render: (h): VNode => h(App),
}).$mount('#app');
