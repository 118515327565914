import {
  Amendment,
  addSignature as baseAddSignature,
  getDataToSign as baseGetDataToSign,
  Protocol,
  Record,
  SignableData,
} from '@anschuetz-elog/common';

import useFeathers from '#/compositions/useFeathers';
import { useAuthStore } from '#/stores/auth';
import { useClientDeviceStore } from '#/stores/clientDevice';

export function getProtocolDataToSign(data: Protocol): Record | Amendment {
  const authStore = useAuthStore();

  if (!authStore.user) {
    throw new Error('No user logged in');
  }
  const feathers = useFeathers();
  return baseGetDataToSign(feathers, data, authStore.user);
}

export function addSignature(data: SignableData): void {
  const authStore = useAuthStore();
  const clientDeviceStore = useClientDeviceStore();

  if (!authStore.user || !authStore.key) {
    throw new Error('No user logged in');
  }

  const feathers = useFeathers();
  baseAddSignature(feathers, data, authStore.user, authStore.key, clientDeviceStore.clientDeviceId);
}
