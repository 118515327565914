import { SystemInfo } from '@anschuetz-elog/common';
import { i18n } from '@anschuetz-elog/frontend-core';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { compare } from 'compare-versions';
import { defineStore } from 'pinia';
import Vue, { ref } from 'vue';

import { resetDatabases } from '@/lib/offline/databases';
import { pushOfflineQueue } from '@/lib/offline/offline-queue';
import { resetApp } from '@/utilities';
import useFeathers from '#/compositions/useFeathers';

import { useOfflineStore } from './offline';

/** @deprecated */
const LS_BACKEND_INTEGRITY_CHECK_BACKEND_ID_KEY = 'backend-integrity-check:backend-id';
const LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY = 'backend-integrity-check:backend';

export const useBackendIntegrityCheckStore = defineStore('backend-integrity-check', () => {
  const backend = useStorage<
    | {
        id: string;
        version?: string;
      }
    | undefined
  >(LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY, undefined, undefined, { serializer: StorageSerializers.object });
  const checked = ref(false);
  const feathers = useFeathers();

  // migrate deprecated backend-id from local storage
  if (!backend.value) {
    const backendId = localStorage.getItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_ID_KEY);
    if (backendId !== null) {
      backend.value = { id: backendId };
      localStorage.removeItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_ID_KEY);
    }
  }

  function setChecked(newChecked: boolean): void {
    checked.value = newChecked;
  }

  async function storeSystemInfoForOffline(systemInfo: SystemInfo): Promise<void> {
    try {
      // we first remove the already existing system info so that we can always use create later
      await feathers.service('systemInfo').update(SystemInfo.FixedId, systemInfo, { onlineEvent: true });
    } catch (error) {
      // Either it is the first time the app loads on a device or we are in online only mode
      // so we try to create the system info now
      try {
        await feathers.service('systemInfo').create(systemInfo, { onlineEvent: true });
      } catch (error) {
        // ignore error because it is most likely that we are in online only mode
      }
    }
  }

  async function checkBackendIntegrity(): Promise<void> {
    let systemInfo: SystemInfo;
    try {
      // the id is ignored here as we only have system-info once
      systemInfo = await feathers.service('systemInfo').get(SystemInfo.FixedId, { onlineRequest: true });
    } catch (error) {
      let dialog;
      try {
        dialog = await Vue.dialog.confirm(i18n.t('general.backend_integrity_failed') as string, {
          loader: true,
          customClass: 'dialog',
          okText: i18n.t('general.try_again') as string,
          cancelText: i18n.t('general.close') as string,
        });
        dialog.close();
        await checkBackendIntegrity();
      } catch (error) {
        dialog?.close();
      }
      return;
    }
    const backendId = systemInfo.backendId || '';
    void storeSystemInfoForOffline(systemInfo);

    // this means the local storage was cleared and now should be updated
    // with the newest server-version-uuid from backend
    if (backend.value === undefined) {
      backend.value = { id: backendId, version: systemInfo.backendVersion };
      checked.value = true;
      return;
    }

    // backend-ids do not match, so we need to reset whole app
    if (backend.value.id !== backendId) {
      let dialog;
      try {
        // open dialog and ask user to proceed
        dialog = await Vue.dialog.alert(i18n.tc('general.backend_integrity_reset'), {
          loader: true,
          customClass: 'dialog',
          okText: 'Confirm',
        });
      } catch (err) {
        // Clicked on cancel, so we do nothing
        return;
      }

      await resetApp();

      Vue.toasted.success(i18n.tc('general.reset_app'), {
        position: 'bottom-center',
        duration: 3000,
      });

      dialog.close();
      return;
    }

    if (
      systemInfo.versionRequiringClientDataReset &&
      compare(systemInfo.backendVersion || '0.0.0', systemInfo.versionRequiringClientDataReset, '>=') &&
      compare(backend.value?.version || '0.0.0', systemInfo.versionRequiringClientDataReset, '<')
    ) {
      const dialog = await Vue.dialog.alert(i18n.tc('general.client_data_reset_notification'), {
        loader: true,
        customClass: 'dialog info',
      });

      // clear pouchDbs
      await pushOfflineQueue();
      await resetDatabases();
      useOfflineStore().setLastSync({});

      dialog.close();
    }
    backend.value = { id: backendId, version: systemInfo.backendVersion };
    checked.value = true;
  }

  return {
    backend,
    checked,
    setChecked,
    checkBackendIntegrity,
  };
});
