import { defineStore } from 'pinia';
import { ref } from 'vue';

import useFeathers from '#/compositions/useFeathers';
import { Debug } from '#/lib';

import { ONLINE_RECONNECT_STRATEGY } from './auth';

const debug = Debug('store:websocket');

export const useWebsocketStore = defineStore('websocket', () => {
  const isConnected = ref(false);
  const isAuthenticated = ref(false);

  async function connect(): Promise<void> {
    try {
      await useFeathers().reAuthenticate(
        true,
        import.meta.env.VITE_APP_MODE !== 'cloud' ? ONLINE_RECONNECT_STRATEGY : undefined,
      );
    } catch (error) {
      // ignore error as most likely not authenticated
    }
    isConnected.value = true;
  }
  function disconnect(): void {
    isConnected.value = false;
  }
  function message(message: string): void {
    // log all messages
    debug('[Websocket] <', message);
  }

  function setAuthenticated(newIsAuthenticated: boolean): void {
    isAuthenticated.value = newIsAuthenticated;
  }

  return {
    isConnected,
    isAuthenticated,
    setAuthenticated,
    connect,
    disconnect,
    message,
  };
});
