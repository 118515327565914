import { ColorMode, SystemStatus } from '@anschuetz-elog/common';
import { vuetify } from '@anschuetz-elog/frontend-core';
import Vue, { watch } from 'vue';

import { useSystemStatusStore } from '@/stores/systemStatus';

let lastColorMode: ColorMode | null = null;
function updateColorMode(systemStatus: SystemStatus | null): void {
  if (!systemStatus || systemStatus.colorMode === lastColorMode) {
    return;
  }
  lastColorMode = systemStatus.colorMode;
  if (systemStatus.colorMode === ColorMode.Day) {
    vuetify.framework.theme.dark = false;
  } else if (systemStatus.colorMode === ColorMode.Night) {
    vuetify.framework.theme.dark = true;
  }
}

export function load(): void {
  Vue.use((): void => {
    const systemStatusStore = useSystemStatusStore();

    // check after each reconnect
    watch(
      () => systemStatusStore.systemStatus,
      (systemStatus) => {
        updateColorMode(systemStatus);
      },
    );
  });
}
