import { ActionManager } from '@anschuetz-elog/common';
import { dynamicConfig, i18n, utilities } from '@anschuetz-elog/frontend-core';
import Vue from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';

import { Debug } from '@/lib';
import { useAuthStore } from '#/stores/auth';
import { useClientDeviceStore } from '#/stores/clientDevice';

const debug = Debug('vue:Router');

export default async function beforeEach(to: Route, from: Route, next: NavigationGuardNext<Vue>): Promise<void> {
  const authStore = useAuthStore();

  // load user session
  try {
    await authStore.loadSession();
  } catch (error) {
    // ignore error as we assume that user is not authenticated and redirect to login page therefor
    return next({ name: 'auth-login' });
  }

  const authenticated = authStore.isAuthenticated;

  debug(`route "${from.fullPath}" >> "${to.path}" auth: ${authenticated ? 'true' : 'false'}`);

  // check if client-device is registered
  const clientDeviceStore = useClientDeviceStore();
  if (
    !dynamicConfig('ONLINE_ONLY') &&
    !clientDeviceStore.clientDeviceId &&
    !to.meta?.availableWithoutClientRegistration
  ) {
    // skip if already on 'setup-client-device'
    if (to.name === 'setup-client-device') {
      return next();
    }

    return next({ name: 'setup-client-device' });
  }
  // check if client-device registration is inactive
  if (dynamicConfig('ONLINE_ONLY') && to.name === 'setup-client-device') {
    return next({ name: 'home' });
  }

  // (no auth-mode is set (default case) || authentication required) & not authenticated => redirect to login
  if (utilities.hasRouteAuthenticationMode(to, utilities.AuthenticationMode.AUTHENTICATED_ONLY) && !authenticated) {
    authStore.setLoginRedirectUrl(to.path); // save requested url to redirect user after login
    debug('redirect to login');
    return next({ name: 'auth-login' });
  }

  // for unauthenticated users only & authenticated => redirect to home
  if (utilities.hasRouteAuthenticationMode(to, utilities.AuthenticationMode.UNAUTHENTICATED_ONLY) && authenticated) {
    debug('redirect to home');
    return next({ name: 'home' });
  }

  // for unauthenticated users only & authenticated => redirect to home
  if (dynamicConfig('EXTERNAL_CONFIGURATION')) {
    const externallyConfigurable = to.matched.reduce(
      (flag, record) => record.meta.externallyConfigurable || flag,
      false,
    );
    if (externallyConfigurable) {
      debug('redirect to home');
      Vue.toasted.error(i18n.t('general.external_configured') as string, {
        position: 'bottom-center',
        duration: 3000,
      });
      return next({ name: 'home' });
    }
  }

  // ptc master only routes
  if (!dynamicConfig('PTC_MASTER_ENVIRONMENT')) {
    const ptcMasterOnly = to.matched.reduce((flag, record) => record.meta.ptcMasterOnly || flag, false);
    if (ptcMasterOnly) {
      debug('redirect to home');
      Vue.toasted.error(i18n.t('general.unauthorized') as string, {
        position: 'bottom-center',
        duration: 3000,
      });
      return next({ name: 'home' });
    }
  }

  // check if user has the needed abilities for this route
  if (authenticated) {
    const user = authStore.user;
    const routeRules = to.matched
      .map((record) => {
        if (record.meta.authorization) {
          if (!record.meta.authorization.action || !record.meta.authorization.subject) {
            throw new Error('you need to specify both an action and a subject');
          }
          return record.meta.authorization;
        }
      })
      .filter((rule) => rule);

    if (routeRules) {
      const ability = ActionManager.getReference().getAbility(user, {
        ptcMaster: dynamicConfig('PTC_MASTER_ENVIRONMENT') || false,
      });
      const authorized = routeRules.every((rule) => {
        return ability?.can(rule.action, rule.subject);
      });

      if (!authorized) {
        debug('unauthorized');
        Vue.toasted.error(i18n.t('general.unauthorized') as string, {
          position: 'bottom-center',
          duration: 3000,
        });
        return next({ name: 'home' });
      }
    }
  }

  return next();
}
