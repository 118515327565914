import { AUTH_RESOURCES, Backup, BackupStatus } from '@anschuetz-elog/common';
import { dynamicConfig, i18n, utilities } from '@anschuetz-elog/frontend-core';
import Vue, { watch } from 'vue';

import useFeathers from '#/compositions/useFeathers';
import { useAuthStore } from '#/stores/auth';

async function checkForBackupToBeDownloaded(userId: string): Promise<void> {
  const feathers = useFeathers();
  const backups = (await feathers.service('backup').find({ paginate: false })) as Backup[];
  const readyDownloads = backups.filter(
    (backup) => backup.requestedBy._id === userId && backup.status === BackupStatus.READY,
  );
  if (readyDownloads.length > 0) {
    Vue.toasted.info(i18n.t('backup.backups_waiting_for_download') as string, {
      position: 'bottom-center',
      duration: 5000,
      action: {
        text: i18n.t('backup.failed_see_details') as string,
        push: {
          name: 'backup',
        },
      },
    });
  }
}

export function load(): void {
  if (dynamicConfig('EXTERNAL_CONFIGURATION')) {
    return;
  }
  Vue.use((): void => {
    const authStore = useAuthStore();
    watch(
      () => authStore.isAuthenticated,
      (isAuthenticated) => {
        if (!isAuthenticated) {
          return;
        }
        const { userId } = authStore;
        if (userId && Vue.prototype.$ability.can('find', AUTH_RESOURCES.BACKUP)) {
          void checkForBackupToBeDownloaded(userId);
        }
      },
    );

    const feathers = useFeathers();
    feathers.service('backup').on('updated', (backup: Backup) => {
      if (!authStore.isAuthenticated) {
        return;
      }
      if (authStore.userId !== backup.requestedBy._id) {
        return;
      }
      if (backup.status === BackupStatus.READY) {
        Vue.toasted.info(i18n.t('backup.backup_ready') as string, {
          position: 'bottom-center',
          duration: 5000,
          action: {
            text: i18n.t('backup.download') as string,
            onClick: (e, toastObject) => {
              void utilities.download(`api/v1/rest/backup/${backup._id}?download`);
              toastObject.goAway(0);
            },
          },
        });
      } else if (backup.status === BackupStatus.FAILED) {
        Vue.toasted.error(i18n.t('backup.backup_failed') as string, {
          position: 'bottom-center',
          duration: 5000,
          action: {
            text: i18n.t('backup.failed_see_details') as string,
            push: {
              name: 'backup',
            },
          },
        });
      }
    });
  });
}
