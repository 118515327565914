import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import AuthRoutes from '@/router/auth';
import SettingsRoutes from '@/router/settings';
import SetupRoutes from '@/router/setup';

import beforeEach from './beforeEachHook';

export default function createRouter(): VueRouter {
  Vue.use(VueRouter);

  const routes: RouteConfig[] = [
    ...SetupRoutes,
    ...AuthRoutes,
    ...SettingsRoutes,
    {
      path: '',
      name: 'home',
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '*',
      component: () => import('@/views/PageNotFound.vue'),
    },
    {
      path: '/alert',
      name: 'alerts',
      component: () => import('@/views/alert/Alerts.vue'),
    },
  ];

  const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
    scrollBehavior(to) {
      if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    },
  });

  router.beforeEach(beforeEach);
  return router;
}
