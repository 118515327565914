<template>
  <div class="app-bar bg-white">
    <div class="app-bar-inner max-w-app">
      <v-menu tile offset-y content-class="menu-content">
        <template #activator="{ on }" tile>
          <v-btn icon data-test="menu-activator" v-on="on">
            <Icon name="action/menu" color="gray_first" data-test="button-menu" />
          </v-btn>
        </template>

        <v-card tile>
          <v-list tile class="menu-item-list" data-test="list-menu">
            <template v-if="isAuthenticated">
              <v-list-item active-class="item-active" tile link exact :to="{ name: 'home' }">
                <Icon name="action/home" class="mr-3" color="gray_first" />
                <v-list-item-title v-t="'home'" class="menu-title gray_first--text" />
              </v-list-item>
              <v-list-group v-for="group in menu" :key="group.title" :color="group.color" no-action @click.stop>
                <template #activator>
                  <Icon :name="group.icon" class="mr-3" :color="group.color" />
                  <v-list-item-content :class="`${group.color}--text`" tile>
                    <v-list-item-title class="menu-title" v-text="group.title" />
                  </v-list-item-content>
                </template>
                <template #appendIcon>
                  <Icon class="expand-icon" name="arrow/chevronDown" />
                </template>
                <v-list-item-group id="menuBar">
                  <template v-for="item in group.items">
                    <v-list-item
                      v-if="!item.items"
                      :key="item.id"
                      tile
                      :to="typeof item.action !== 'function' ? item.action : undefined"
                      :disabled="item.action === undefined"
                      class="sub-items"
                      active-class="item-active"
                      @click.native="typeof item.action === 'function' ? item.action() : undefined"
                    >
                      <img v-if="item.icon.custom" :src="item.icon.custom" class="item-content mr-3 custom-book-icon" />
                      <Icon
                        v-else-if="item.icon.name"
                        :name="item.icon.name"
                        class="item-content mr-3"
                        :color="group.color"
                      />
                      <v-list-item-content :class="['item-content', `${group.color}--text`]">
                        <v-list-item-title class="menu-title" v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-group
                      v-else
                      :key="item.title"
                      :color="group.color"
                      no-action
                      sub-group
                      prepend-icon=""
                      class="sub-group"
                      @click.stop
                    >
                      <template #activator>
                        <Icon :name="item.icon" class="mr-3" :color="group.color" />
                        <v-list-item-content :class="`${group.color}--text`" tile>
                          <v-list-item-title class="menu-title">{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <template #appendIcon>
                        <Icon class="expand-icon" name="arrow/chevronDown" />
                      </template>

                      <v-list-item
                        v-for="subItem in item.items"
                        :key="subItem.id"
                        tile
                        :to="typeof subItem.action !== 'function' ? subItem.action : undefined"
                        :disabled="subItem.action === undefined"
                        class="sub-items"
                        active-class="item-active"
                        @click.native="typeof subItem.action === 'function' ? subItem.action() : undefined"
                      >
                        <img
                          v-if="subItem.icon.custom"
                          :src="subItem.icon.custom"
                          class="item-content mr-3 custom-book-icon"
                        />
                        <Icon
                          v-else-if="subItem.icon.name"
                          :name="subItem.icon.name"
                          class="item-content mr-3"
                          :color="group.color"
                        />
                        <v-list-item-content :class="['item-content', `${group.color}--text`]">
                          <v-list-item-title class="menu-title" v-text="subItem.text" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-group>
                  </template>
                </v-list-item-group>
              </v-list-group>
            </template>
            <template v-else-if="!isUpdatingServiceWorker">
              <v-list-item active-class="item-active" tile link exact :to="{ name: 'about' }">
                <Icon name="logo/raytheonLogo" class="mr-3" color="gray_first" />
                <v-list-item-title v-t="'about.about'" class="menu-title gray_first--text" />
              </v-list-item>
              <v-list-item active-class="item-active" tile link exact :to="{ name: 'user-manual' }">
                <Icon name="action/userManual" class="mr-3" color="gray_first" />
                <v-list-item-title v-t="'user_manual.user_manual'" class="menu-title gray_first--text" />
              </v-list-item>
              <v-list-item active-class="item-active" tile link exact :to="{ name: 'auth-login' }">
                <Icon name="action/login" class="mr-3" color="gray_first" />
                <v-list-item-title v-t="'login'" class="menu-title gray_first--text" />
              </v-list-item>
            </template>

            <v-divider />

            <v-list-item v-if="isAuthenticated">
              <v-list-item-title class="logged-in-details gray_first--text">
                <span v-t="'logged_in_as'" />
                <span>:</span>
                <b class="username">{{ userName }}</b>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAuthenticated" link @click="logout">
              <v-list-item-title v-t="'logout'" class="menu-title gray_first--text" />
              <Icon name="action/logout" color="gray_first" />
            </v-list-item>
            <v-divider />
            <v-list-item v-model="isNightModeActive" active-class="item-active" link @click="toggleNightMode">
              <v-list-item-title class="menu-title gray_first--text">{{ $t('night_mode') }}</v-list-item-title>
              <Icon name="circle/nightModeOn" color="gray_first" />
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <router-link :to="{ name: 'home' }" class="d-flex" :disabled="isUpdatingServiceWorker" data-test="app-bar-logo">
        <Icon name="logo/logoWithText" sizing="auto" class="logo" color="gray_first" />
      </router-link>

      <Icon name="logo/formularus/formularusVerlag" sizing="auto" color="primary" class="formularus-logo ml-auto" />

      <div class="status">
        <AISIcon class="status-connection" />
        <ConnectionIcon :is-connected="isConnected" :is-sync-pending="isSyncPending" class="status-connection" />

        <router-link
          v-if="activeAlerts.length && isAuthenticated"
          :to="{ name: 'alerts' }"
          data-test="btn-alerts"
          class="status-alerts"
        >
          <v-badge color="error" :content="activeAlerts.length" overlap>
            <Icon name="notification/on" color="error" />
          </v-badge>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Icon, useMenu, vuetify } from '@anschuetz-elog/frontend-core';
import { computed, defineComponent } from 'vue';

import AISIcon from '@/components/AISIcon.vue';
import ConnectionIcon from '@/components/ConnectionIcon.vue';
import useFind from '@/compositions/useFind';
import { useOfflineStore } from '@/stores/offline';
import { useServiceWorkerStore } from '@/stores/serviceWorker';
import { useAuthStore } from '#/stores/auth';
import { useWebsocketStore } from '#/stores/websocket';

export default defineComponent({
  name: 'AppBar',
  components: { Icon, ConnectionIcon, AISIcon },
  setup() {
    const { menu } = useMenu();

    const serviceWorkerStore = useServiceWorkerStore();
    const isUpdatingServiceWorker = computed(() => serviceWorkerStore.updating);

    const offlineStore = useOfflineStore();
    const isSyncPending = computed(() => offlineStore.isSyncPending);

    const websocketStore = useWebsocketStore();
    const isConnected = computed(() => websocketStore.isConnected);

    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const userName = computed(() => authStore.user?.userName || '');

    const { data: activeAlerts } = useFind(
      'alert',
      computed(() => {
        if (!isAuthenticated.value) {
          return undefined;
        }
        return { query: { state: 'active' } };
      }),
    );

    async function logout() {
      await authStore.logout();
    }

    const isNightModeActive = computed(() => {
      return vuetify.framework.theme.dark;
    });

    function toggleNightMode() {
      vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
    }
    return {
      userName,
      menu,
      isUpdatingServiceWorker,
      isAuthenticated,
      isNightModeActive,
      isConnected,
      isSyncPending,
      activeAlerts,
      logout,
      toggleNightMode,
    };
  },
});
</script>

<style>
/*
This is used so that anchor links scroll to the correct position respecting the app-bar height
Check: https://stackoverflow.com/a/28824157/8461267
*/
:target:before {
  content: '';
  display: block;
  height: 54px; /* fixed header height*/
  margin: -54px 0 0; /* negative fixed header height */
}
</style>

<style lang="scss" scoped>
.app-bar {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 54px;
  z-index: 1000;
  display: flex;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.app-bar-inner {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

.menu-item-list {
  padding: 0;
}

.logo {
  cursor: pointer;
  margin-left: 16px;
  align-items: center;
  display: inline-flex;
}

.status {
  margin: 0 11px;
  padding-top: 11px;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.status-connection,
.status-alerts {
  margin-left: 8px;
}

.status-alerts {
  padding-top: 5px;
}

.menu-title {
  display: flex;
  text-align: left;
}

.formularus-logo {
  width: 60px;
}
/* this class is set via prop of v-list-item, it was not possible otherwise */
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.item-active {
  background: var(--v-primary-base);
  color: var(--v-white-base) !important;
}

/* this class is set via prop of v-list-item, it was not possible otherwise */
/* eslint-disable-next-line vue-scoped-css/no-unused-selector */
.menu-content {
  top: 54px !important;
  border: 0;
  z-index: 1000 !important;
  border-radius: 0px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.sub-items {
  padding-left: 44px;
}

.sub-group {
  padding-left: 12px;
}

.sub-group .sub-items {
  padding-left: 60px !important;
}

.logged-in-details {
  display: flex;
}

#menuBar .item-active .item-content {
  color: var(--v-white-base) !important;
}

.username {
  margin-left: 8px;
}

::v-deep .v-badge__badge {
  color: var(--v-white-base) !important;
}

::v-deep .v-list-group__header.v-list-item--active .expand-icon {
  transform: rotate(180deg);
}

.custom-book-icon {
  /** filter calculated by https://isotropic.co/tool/hex-color-to-css-filter/ but brightness(0) is required
  as first filter to make icon black which is required for the calculator in the link.
  target color is the primary color definition */
  filter: brightness(0) invert(28%) sepia(76%) saturate(833%) hue-rotate(166deg) brightness(90%) contrast(99%);
}

.item-active .custom-book-icon {
  /** filter calculated by https://isotropic.co/tool/hex-color-to-css-filter/ but brightness(0) is required
  as first filter to make icon black which is required for the calculator in the link.
  target color is the primary color definition */
  filter: brightness(0) invert(1);
}

.theme--dark .custom-book-icon {
  /** filter calculated by https://isotropic.co/tool/hex-color-to-css-filter/ but brightness(0) is required
  as first filter to make icon black which is required for the calculator in the link.
  target color is the primary color definition for the dark theme */
  filter: brightness(0) invert(17%) sepia(74%) saturate(2008%) hue-rotate(183deg) brightness(89%) contrast(96%);
}
</style>
