import { SyncState } from '@anschuetz-elog/common';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const LS_LAST_SYNC_KEY = 'offline:websocket';

export const useOfflineStore = defineStore('offline', () => {
  const lastSync = useStorage<SyncState>(LS_LAST_SYNC_KEY, {});
  const isSyncPending = ref(false);

  function setLastSync(newLastSync: SyncState): void {
    lastSync.value = newLastSync;
  }

  function setIsSyncPending(newIsSyncPending: boolean): void {
    isSyncPending.value = newIsSyncPending;
  }

  return {
    lastSync,
    isSyncPending,
    setLastSync,
    setIsSyncPending,
  };
});
