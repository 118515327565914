import { Application } from '@anschuetz-elog/common';
import { AdapterService } from '@feathersjs/adapter-commons';
import { Application as FeathersApplication, Id, ServiceMethods } from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import { io } from 'socket.io-client';

import Debug from '#/lib/debug';
import { useWebsocketStore } from '#/stores/websocket';

const debug = Debug('lib:feathers');

export type ClientApplication = Application;

export function connect(feathersClient: Application, storeConnectionState?: boolean): void {
  const socket = io({
    path: `${import.meta.env.BASE_URL}api/v1/socket`,
    transports: ['websocket'],
    autoConnect: false,
  });

  const websocketStore = useWebsocketStore();

  feathersClient.configure(socketio(socket, { timeout: 40 * 1000 }));

  if (storeConnectionState) {
    socket.on('connect', () => {
      debug('Backend: connected ;-)');
      void websocketStore.connect();
    });

    socket.on('disconnect', () => {
      debug('Backend: disconnected');
      websocketStore.disconnect();
    });
  }

  feathersClient.on('disconnect', () => {
    socket.disconnect();
  });

  // start first connection attempt after all listeners got registered
  socket.open();
}

export type PotentialIds = {
  id?: Id;
  _id?: Id;
};

export function getId(item: PotentialIds): Id {
  if (item.id) {
    return item.id;
  }
  if (item._id) {
    return item._id;
  }
  throw new Error('Unable to retrieve id from item');
}

export type ServiceTypes = Application extends FeathersApplication<infer S> ? S : never;

// TODO: the checks below are necessary due to the prerelease state of feathers v5. The problem there is
// that the AdapterService interface is not yet updated and is not compatible with the ServiceMethods interface
// and therefor needs to be checked separately.
export type ServiceModel<T extends keyof ServiceTypes> = ServiceTypes[T] extends AdapterService<infer M1>
  ? M1
  : ServiceTypes[T] extends ServiceMethods<infer M2>
    ? M2
    : never;
