import {
  getBookForRole,
  getElementValue,
  isAccessToBookRole,
  isHeadOfBookRole,
  isProtocolLink,
  LocalCache,
  Protocol,
  ProtocolContentData,
  Ref,
  Role,
  User,
} from '@anschuetz-elog/common';
import { clone } from 'lodash';
import moment, { Duration } from 'moment';
import streamSaver from 'streamsaver';
import Vue, { computed, Ref as VueRef } from 'vue';
import { Route, RouteConfig } from 'vue-router';
import { ToastObject } from 'vue-toasted';

import useFeathers from '#/compositions/useFeathers';
import {
  MOMENT_DATE,
  MOMENT_DATE_TIME,
  MOMENT_DATE_TIME_WITH_SECONDS,
  MOMENT_TIME,
  MOMENT_TIME_MIN_SEC,
} from '#/config/time';
import logger from '#/logger';

import i18n from './i18n';
import { useAuthStore } from './stores/auth';

export function normalizeTimestamp(timestamp: moment.MomentInput): moment.Moment {
  let normalizedTimestamp = moment(timestamp);
  if (import.meta.env?.VITE_SMT_HANDLING_ENABLED) {
    normalizedTimestamp = normalizedTimestamp.utc(true);
  }
  return normalizedTimestamp;
}

/**
 * Reverse the rounding to the maximum value which would result in the same date time when round it again.
 *
 * @param direction true to reverse a floor rounding, false to reverse a ceil rounding
 * @param dateTime the date time for which to reverse the rounding
 */
export const reverseRoundDateTime = (direction: boolean, dateTime?: moment.MomentInput): moment.Moment => {
  const roundedDateTime = import.meta.env?.VITE_SMT_HANDLING_ENABLED
    ? roundDateTime(moment(dateTime).toISOString())
    : roundDateTime(dateTime);
  if (direction) {
    roundedDateTime.add(29, 'seconds').add(999, 'milliseconds');
  } else {
    roundedDateTime.subtract(30, 'seconds');
  }
  return roundedDateTime;
};

export const roundDateTime = (dateTime?: moment.MomentInput): moment.Moment => {
  const momentDateTime = import.meta.env?.VITE_SMT_HANDLING_ENABLED
    ? moment.parseZone(dateTime).utc(true)
    : moment(dateTime);
  const decimalSeconds = (momentDateTime.seconds() + momentDateTime.milliseconds() / 1000) / 60;
  if (Math.round(decimalSeconds) > decimalSeconds) {
    momentDateTime.add(1, 'minute');
  }
  momentDateTime.startOf('minute');
  return momentDateTime;
};

export const format = (dateTime?: string, format?: string, disableRounding = false): string => {
  // if (!dateTime) return '';
  let momentDateTime: moment.Moment;
  if (!disableRounding) {
    momentDateTime = roundDateTime(dateTime);
  } else if (import.meta.env?.VITE_SMT_HANDLING_ENABLED) {
    momentDateTime = moment.parseZone(dateTime).utc(true);
  } else {
    momentDateTime = moment(dateTime);
  }
  if (!momentDateTime.isValid()) {
    return dateTime?.toString() || '';
  }
  return momentDateTime.format(format);
};

export const dateStr = (dateTime?: string, disableRounding = false): string => {
  return format(dateTime, MOMENT_DATE, disableRounding);
};

export const timeStr = (dateTime?: string): string => {
  return format(dateTime, MOMENT_TIME);
};

export const minSecStr = (dateTime?: string): string => {
  return format(dateTime, MOMENT_TIME_MIN_SEC, true);
};

export const dateTimeStr = (dateTime?: string, withSeconds = false, disableRounding = false): string => {
  return format(dateTime, withSeconds ? MOMENT_DATE_TIME_WITH_SECONDS : MOMENT_DATE_TIME, disableRounding);
};

/**
 * Same as dateTimeStr but with a prefix for ships mean time.
 */
export function dateTimeStrShipsMeanTime(dateTime?: string): string {
  if (import.meta.env?.VITE_SMT_HANDLING_ENABLED) {
    return `${i18n.tc('protocol.ships_mean_time_acronym')} ${dateTimeStr(dateTime)}`;
  }
  return dateTimeStr(dateTime);
}

type DurationPart = Pick<
  Duration,
  'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds'
>;

export const durationStr = (duration: Duration): string => {
  const parts: (keyof DurationPart)[] = ['years', 'months', 'days', 'hours', 'minutes'];
  return parts
    .map((part) => {
      const value = duration[part]();
      if (value === 0) {
        return null;
      } else if (value === 1) {
        return `1 ${part.substr(0, part.length - 1)}`;
      } else {
        return `${value} ${part}`;
      }
    })
    .filter((part) => part !== null)
    .join(', ');
};

export const renameKey = (
  obj: Record<string | string | symbol, unknown>,
  key: string,
  newKey: string,
): Record<string | string | symbol, unknown> => {
  const clonedObj = clone(obj);
  const value = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = value;
  return clonedObj;
};

export function prefixRoutes(prefix: string, routes: RouteConfig[]): RouteConfig[] {
  return routes.map((route) => ({
    ...route,
    path: `${prefix}/${route.path}`,
  }));
}

export function applyAuthorization(authorization: Record<string, string>, routes: RouteConfig[]): RouteConfig[] {
  return routes.map((route) => ({
    ...route,
    meta: { ...route.meta, authorization },
  }));
}

// there are three different auth-modes and one default one:
// - authenticatedOnly
// - unauthenticatedOnly
// - everyone / don't care
// - undefined (default which works like: authenticatedOnly)
export enum AuthenticationMode {
  AUTHENTICATED_ONLY = 'authenticatedOnly',
  UNAUTHENTICATED_ONLY = 'unauthenticatedOnly',
  DONT_CARE = 'dontCare',
}

export function hasRouteAuthenticationMode(route: Route, mode: AuthenticationMode): boolean {
  const authMode = route.matched.map((record) => record.meta.auth || null);

  if (mode === AuthenticationMode.AUTHENTICATED_ONLY) {
    const authModeUnset = !authMode.some((mode) => mode !== null);
    const authenticatedOnly = authMode.some((mode) => mode === AuthenticationMode.AUTHENTICATED_ONLY);
    return authModeUnset || authenticatedOnly;
  }

  if (mode === AuthenticationMode.UNAUTHENTICATED_ONLY) {
    const unauthenticatedOnly = authMode.some((mode) => mode === AuthenticationMode.UNAUTHENTICATED_ONLY);
    return unauthenticatedOnly;
  }

  /* Not sure if we ever need to check this
  if (mode === AuthenticationMode.DONT_CARE) {
    const dontCare = authMode.some(mode => mode === AuthenticationMode.DONT_CARE);
    return dontCare;
  }
  */

  return false;
}

/**
 * return initials of a user
 * @param users list of all users
 * @param userRef ref to a specific user
 */
export function getUserInitials(userRef: Ref<User>): string {
  const feathers = useFeathers();
  try {
    const user = feathers.get('localCache').getUserById(userRef._id);
    const names = user.name.split(' ');
    const firstLetters = names.reduce((result: string, currentName) => {
      return `${result}${currentName.charAt(0).toUpperCase()}`;
    });
    return `${firstLetters[0]}${firstLetters[firstLetters.length - 1]}`;
  } catch (error) {
    return '??';
  }
}

export function getUserName(userRef: Ref<User>): string {
  const feathers = useFeathers();
  try {
    return feathers.get('localCache').getUserById(userRef._id).userName;
  } catch (error) {
    return '??';
  }
}

export function getUserFullName(userRef: Ref<User>): string {
  const feathers = useFeathers();
  try {
    return feathers.get('localCache').getUserById(userRef._id).name;
  } catch (error) {
    return '??';
  }
}

export function getProtocolContentData<T extends ProtocolContentData = ProtocolContentData>(
  protocol: Protocol,
  protocolContentTypeId: string,
): T {
  const data = protocol.contents.find(({ type: { _id: id } }) => id === protocolContentTypeId)?.data;
  return (data || {}) as T;
}

export function getProtocolTypeName(protocol: Protocol): string {
  const protocolTypeId = '_id' in protocol.type ? protocol.type._id : '';
  const feathers = useFeathers();
  try {
    const protocolType = feathers.get('localCache').getProtocolType(protocolTypeId);
    if (protocolType.elementForType) {
      const contentTypeId = protocolType.elementForType.contentType._id;
      const contentType = feathers.get('localCache').getProtocolContentType(contentTypeId);
      let value = getElementValue(protocol, contentType, protocolType.elementForType.elementId);
      if (isProtocolLink(value)) {
        value = value.value;
      }
      if (value !== undefined && !Array.isArray(value) && typeof value !== 'object') {
        return `${value}`;
      }
    }
    return protocolType.name;
  } catch (error) {
    return i18n.tc('unknown');
  }
}

export function getProtocolTimestampAndEventTime(protocol: Protocol): { timestamp: string; eventTime: string } {
  const protocolTypeId = '_id' in protocol.type ? protocol.type._id : '';
  const feathers = useFeathers();
  const protocolTimestamp = protocol.timestamp;
  try {
    const protocolType = feathers.get('localCache').getProtocolType(protocolTypeId);
    const timestamp = protocolType.showUTC ? moment(protocolTimestamp).utc().toISOString() : protocolTimestamp;
    const eventTime = protocolType.showUTC ? `${timeStr(timestamp)} [UTC]` : timeStr(timestamp);

    return { timestamp, eventTime };
  } catch (error) {
    return { timestamp: protocolTimestamp, eventTime: timeStr(protocolTimestamp) };
  }
}

export function getChecklistTypeName(protocol: Protocol): string {
  const checklistTypeId = !('_id' in protocol.type) ? protocol.type.ref._id : '';

  const feathers = useFeathers();
  try {
    return feathers.get('localCache').getChecklistType(checklistTypeId).name;
  } catch (error) {
    return i18n.tc('unknown');
  }
}

/**
 * compare to timestamps and return
 *     1 if a < b
 *    -1 if a > b
 *     0 else
 * @param a a timestamp encoded as a Date object or a string
 * @param b a a timestamp encoded as a Date object or a string
 */
export function compareTimestamps(a: string, b: string): number {
  const aDate = moment(a);
  const bDate = moment(b);
  if (aDate.isBefore(bDate)) {
    return 1;
  }
  if (aDate.isAfter(bDate)) {
    return -1;
  }
  return 0;
}

export function formatFileSize(a: number, b = 2): string {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))}
    ${['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]}`;
}

export function getRoleLabel(role: Role, localCache: LocalCache): string {
  if (isHeadOfBookRole(role)) {
    const book = getBookForRole(role, localCache.getBooks());
    if (!book) {
      return i18n.tc('unknown');
    }
    return i18n.tc(`user.role_name_mapping.head_of`, undefined, { book: book.name });
  }
  if (isAccessToBookRole(role)) {
    const book = getBookForRole(role, localCache.getBooks());
    if (!book) {
      return i18n.tc('unknown');
    }
    return i18n.tc(`user.role_name_mapping.access_to`, undefined, { book: book.name });
  }
  return i18n.tc(`user.role_name_mapping.${role.name}`);
}

export async function download(url: string): Promise<void> {
  let startToast: ToastObject | undefined = undefined;
  try {
    startToast = Vue.toasted.info(i18n.tc('file_download.triggered'), {
      position: 'bottom-center',
      duration: undefined, // shall stay visible until response finished
    });
    const res = await fetch(`${import.meta.env.BASE_URL}${url}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${useAuthStore().accessToken}`,
      },
    });
    startToast.goAway();

    if (!res.ok || !res.body) {
      throw new Error(`Download failed. Received status code ${res.status}`);
    }
    const [, filenameHeader] = (res.headers.get('Content-Disposition') || ';=').split(';');
    const filename = (filenameHeader.split('=')[1] || '"elog.file"').split('"')[1];
    Vue.toasted.success(i18n.tc('file_download.started'), {
      position: 'bottom-center',
      duration: 3000,
    });

    // registering local stream saver mitm ("man in the middle")
    // see file-saver/README.md for the reason
    streamSaver.mitm = '/file-saver/mitm.html';

    const reader = res.body.getReader();
    const fileStream = streamSaver.createWriteStream(filename);
    const writer = fileStream.getWriter();

    const readAndWrite = async (): Promise<void> => {
      const { value, done } = await reader.read();
      if (done) {
        await writer.close();
      } else {
        await writer.write(value);
        await writer.ready;
        await readAndWrite();
      }
    };

    await readAndWrite();
    logger.info('Successfully downloaded file from %s', url);
  } catch (error) {
    if (startToast) {
      startToast.goAway();
    }
    Vue.toasted.error(i18n.tc('file_download.failed'), {
      position: 'bottom-center',
      duration: 3000,
    });
    logger.error('Error occurred when downloading file from %s', url);
  }
}

export const getDropzoneOptions = (url: string): VueRef<Dropzone.DropzoneOptions> => {
  return computed(() => ({
    url,
    uploadMultiple: false,
    timeout: 60 * 60 * 1000, // 1hour
    maxFilesize: 5000, // 5gb
    autoProcessQueue: false,
    headers: { Authorization: `Bearer ${useAuthStore().accessToken}` },
  }));
};
