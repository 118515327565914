import { ClientDevice } from '@anschuetz-elog/common';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { Ref } from 'vue';

import useFeathers from '#/compositions/useFeathers';

const LS_DEVICE_ID = 'client-device:client-device-id';

export const useClientDeviceStore = defineStore('client-device', () => {
  const clientDeviceId: Ref<string | null> = useStorage<string | null>(LS_DEVICE_ID, null);
  const feathers = useFeathers();

  function saveClientDeviceId(newClientDeviceId: string | null): void {
    clientDeviceId.value = newClientDeviceId;
  }

  async function createClientDevice(name: string): Promise<void> {
    const clientDevice = await feathers.service('clientDevice').create(new ClientDevice({ name }));

    saveClientDeviceId(clientDevice._id);
  }

  function unregisterClientDevice(): void {
    saveClientDeviceId(null);
  }

  return {
    clientDeviceId,
    saveClientDeviceId,
    createClientDevice,
    unregisterClientDevice,
  };
});
