import { dynamicConfig } from '@anschuetz-elog/frontend-core';
import PouchDB from 'pouchdb-browser';
import PouchDBFind from 'pouchdb-find';
import Vue, { watch } from 'vue';

import { useBackendIntegrityCheckStore } from '@/stores/backendIntegrityCheck';
import useFeathers from '#/compositions/useFeathers';

import { registerOfflineServices } from './offline-services';
import { safeDatabaseSync } from './sync-app';

export function load(): void {
  if (dynamicConfig('ONLINE_ONLY')) {
    return;
  }

  // PouchDB plugins: pouchdb-find (included in the monorepo) and LiveFind (external plugin)
  PouchDB.plugin(PouchDBFind);

  Vue.use((): void => {
    // add all your service which should be offline available
    const feathers = useFeathers();
    const services = registerOfflineServices(feathers);

    const backendIntegrityCheckStore = useBackendIntegrityCheckStore();

    // sync after each reconnect, triggered by a change in the state of backendIntegrityCheck
    watch(
      () => backendIntegrityCheckStore.checked,
      async (backendIntegrityChecked: boolean) => {
        if (backendIntegrityChecked) {
          await safeDatabaseSync(services);
        }
      },
    );
  });
}
