<template>
  <Box
    v-if="!Array.isArray(repetitions)"
    data-test="protocolContentDetails"
    class="flex flex-col"
    :class="{ crossed: deleted }"
    title-centered
    is-expandable
    :title="repetitions.title"
    :content-hidden="contentHidden"
    @expansion-click="toggleHidden"
  >
    <div fluid class="py-[5px] mb-4">
      <div class="justify-start">
        <div v-if="instruction" class="m-2">
          {{ instruction }}
        </div>
        <Form
          v-if="repetitions.schema"
          data-test="form-details"
          :value="repetitions.data"
          :schema="repetitions.schema"
          :peer-dependency-schema="peerDependencySchema"
          :peer-dependency-value="peerDependencyContentData"
          class="flex flex-col basis-full flex-grow-0 gap-y-2 px-2"
          :edit="edit"
          :previous-data="previousData"
          :links="links"
          @valid="onValidation('content', $event)"
          @input="updateData($event)"
          @protocollink:takeover-data="$emit('protocollink:takeover-data', $event)"
        >
          <div v-if="isLoading" class="d-flex justify-space-around py-2">
            <v-progress-circular color="primary" indeterminate />
          </div>
          <p v-else-if="!protocolContentType" v-t="'protocol.unsupported_type'" />
        </Form>
      </div>
    </div>
  </Box>
  <Fragment v-else>
    <Box
      v-for="(repetition, index) in repetitions"
      :key="index"
      class="flex flex-col"
      :class="{ crossed: deleted }"
      title-centered
      is-expandable
      :content-hidden="contentHidden"
      :title="repetition.title"
      @expansion-click="toggleHidden"
    >
      <div fluid class="py-[5px] mb-4">
        <div class="justify-start">
          <div v-if="instruction" class="m-2">
            {{ instruction }}
          </div>
          <Form
            v-if="repetition.schema"
            :value="repetition.data"
            :schema="repetition.schema"
            :peer-dependency-schema="peerDependencySchema"
            :peer-dependency-value="peerDependencyContentData"
            class="flex flex-col basis-full flex-grow-0 gap-y-2 px-2"
            :edit="edit"
            @valid="onValidation(`${index}`, $event)"
            @input="updateData($event, index)"
            @protocollink:takeover-data="$emit('protocollink:takeover-data', $event)"
          >
            <div v-if="isLoading" class="d-flex justify-space-around py-2">
              <v-progress-circular color="primary" indeterminate />
            </div>
            <p v-else-if="!protocolContentType" v-t="'protocol.unsupported_type'" />
          </Form>
        </div>
      </div>
    </Box>
  </Fragment>
</template>

<script lang="ts">
import {
  Element,
  EquipmentItemElement,
  getElements,
  getEquipmentItemSchema,
  Protocol,
  ProtocolContentData,
  ProtocolContentTypeConfig,
  repeatSchema,
  Schema,
} from '@anschuetz-elog/common';
import { isEqual, omit, pick } from 'lodash';
import { computed, defineComponent, PropType, toRef, watch } from 'vue';

import Form from '#/components/form/Form.vue';
import { getLinkConfigProps } from '#/components/form/utils';
import Box from '#/components/layout/Box.vue';
import useFind from '#/compositions/useFind';
import useGet from '#/compositions/useGet';
import { getHiddenProtocolContentBox, setHiddenProtocolContentBox } from '#/compositions/useProtocols';
import useValidation from '#/compositions/useValidation';
import i18n from '#/i18n';
import { useAuthStore } from '#/stores/auth';

export default defineComponent({
  name: 'ProtocolContentBox',

  components: {
    Box,
    Form,
  },

  props: {
    protocol: {
      type: Object as PropType<Protocol>,
      required: true,
    },

    previousProtocols: {
      type: Array as PropType<Protocol[]>,
      default: () => [],
    },

    contentTypeConfig: {
      type: Object as PropType<ProtocolContentTypeConfig>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    deleted: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocol': (protocol: Protocol): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'protocollink:takeover-data': (_linkedProtocol: Protocol) => true,
  },

  setup(props, { emit }) {
    const contentTypeConfig = toRef(props, 'contentTypeConfig');
    const protocol = toRef(props, 'protocol');
    const previousProtocols = toRef(props, 'previousProtocols');

    const authStore = useAuthStore();

    const userId = computed(() => {
      return authStore.userId;
    });

    const { isValid, onValidation, resetValidation } = useValidation(emit);

    const { data: protocolContentType, isLoading: protocolContentTypeLoading } = useGet(
      'protocolContentType',
      computed(() => contentTypeConfig.value.ref._id),
    );

    const { data: equipmentContentType, isLoading: equipmentContentTypeLoading } = useGet(
      'protocolContentType',
      computed(() => {
        if (!contentTypeConfig.value.equipmentItem) {
          return undefined;
        }
        return contentTypeConfig.value.equipmentItem.contentType._id;
      }),
    );

    const peerDependencyIds = computed(() => (protocolContentType.value?.peerDependencies || []).map((ref) => ref._id));
    const { data: peerDependencies } = useFind(
      'protocolContentType',
      computed(() => ({ query: { _id: { $in: peerDependencyIds.value } } })),
    );

    const equipmentItemElement = computed(() => {
      if (!equipmentContentType.value || !contentTypeConfig.value.equipmentItem) {
        return undefined;
      }
      const equipmentItem = contentTypeConfig.value.equipmentItem;
      return equipmentContentType.value.schema.find(
        (element): element is EquipmentItemElement =>
          element.type === 'equipmentitem' && element._id === equipmentItem.elementId,
      );
    });

    const { data: equipmentItems, isLoading: equipmentItemsLoading } = useFind(
      'equipmentItem',
      computed(() => {
        if (!equipmentItemElement.value) {
          return undefined;
        }
        return { query: { 'list._id': { $eq: equipmentItemElement.value.equipmentList } } };
      }),
    );

    const previousData = computed(() =>
      previousProtocols.value.map(
        (protocol) =>
          protocol.contents.find((content) => content.type._id === contentTypeConfig.value.ref._id)?.data || {},
      ),
    );

    const selectedEquipmentItem = computed(() => {
      const equipmentItemContent = protocol.value.contents.find(
        (content) => equipmentContentType.value && content.type._id === equipmentContentType.value._id,
      );
      if (!equipmentItemContent || !equipmentItemElement.value) {
        return undefined;
      }
      const equipmentItemId = equipmentItemContent.data[equipmentItemElement.value.name];
      if (typeof equipmentItemId !== 'string') {
        return undefined;
      }
      return equipmentItems.value.find((item) => item._id === equipmentItemId);
    });

    const { data: equipmentList, isLoading: equipmentListLoading } = useGet(
      'equipmentList',
      computed(() => equipmentItemElement.value?.equipmentList),
    );

    const isLoading = computed(
      () =>
        protocolContentTypeLoading.value ||
        equipmentContentTypeLoading.value ||
        equipmentItemsLoading.value ||
        equipmentListLoading.value,
    );

    const title = computed(() => {
      if (protocolContentType.value) {
        return protocolContentType.value.name;
      }

      return i18n.tc('protocol.protocol');
    });

    const repetitionCount = computed(() => {
      if (!equipmentList.value || !selectedEquipmentItem.value) {
        return 1;
      }
      const itemElementIdForCount = equipmentList.value.protocolContentTypes.find(
        (config) => config.ref._id === contentTypeConfig.value.ref._id,
      )?.itemElementIdForCount;
      if (!itemElementIdForCount) {
        return 1;
      }
      const itemElementForCount = equipmentList.value.itemSchema.find(
        (itemElement): itemElement is Element =>
          itemElement.type !== undefined && itemElement._id === itemElementIdForCount,
      );
      const count = itemElementForCount
        ? (selectedEquipmentItem.value.itemData[itemElementForCount.name] as number)
        : 1;
      return count;
    });

    const schema = computed(() => {
      if (contentTypeConfig.value.equipmentItem) {
        if (protocolContentType.value && selectedEquipmentItem.value && equipmentList.value) {
          return getEquipmentItemSchema(selectedEquipmentItem.value, equipmentList.value, protocolContentType.value);
        }
        return null;
      }
      if (protocolContentType.value) {
        return protocolContentType.value.schema;
      }
      return null;
    });

    const links = computed(() =>
      protocolContentType.value
        ? getLinkConfigProps(protocol.value, protocolContentType.value._id, contentTypeConfig.value.links)
        : [],
    );

    function getSchema(protocolContentData: ProtocolContentData, repetitionNumber?: number) {
      if (!schema.value) {
        return null;
      }
      if (repetitionNumber !== undefined) {
        return repeatSchema(schema.value, repetitionNumber);
      }
      return schema.value;
    }

    const instruction = computed(() => {
      if (contentTypeConfig.value.equipmentItem && !selectedEquipmentItem.value) {
        return i18n.tc('protocol.select_equipment_item_first', undefined, {
          field: equipmentItemElement.value?.label,
          equipmentList: equipmentContentType.value?.name || '',
        });
      }
      return undefined;
    });

    function getContentData(repetitionNumber?: number): ProtocolContentData {
      if (!schema.value) {
        return {};
      }
      const contentData =
        protocol.value.contents.find((content) => content.type._id === contentTypeConfig.value.ref._id)?.data || {};
      if (repetitionNumber === undefined) {
        return contentData;
      }
      const elementNames = schema.value
        .map((element) => {
          if (element.type === undefined) {
            return null;
          }
          return `${element.name}_${repetitionNumber}`;
        })
        .filter((name): name is string => name !== null);
      return pick(contentData, elementNames);
    }

    const peerDependencyContentData = computed<ProtocolContentData>(() => {
      return protocol.value.contents
        .filter((content) => content.type._id !== contentTypeConfig.value.ref._id)
        .reduce<ProtocolContentData>((allContent, content) => ({ ...allContent, ...content.data }), {});
    });

    const peerDependencySchema = computed<Schema>(() => peerDependencies.value.flatMap(({ schema }) => schema));

    const repetitions = computed(() => {
      if (repetitionCount.value === 0) {
        return [];
      }
      if (repetitionCount.value === 1) {
        const data = getContentData();
        return {
          data,
          title: title.value,
          schema: getSchema(data),
        };
      }
      return Array.from({ length: repetitionCount.value }, (_, i) => i + 1).map((i) => {
        const data = getContentData(i);
        return {
          data,
          title: `${title.value} (${i})`,
          schema: getSchema(data, i),
        };
      });
    });

    watch(repetitions, (newRepetitions, oldRepetitions) => {
      if (!schema.value || isEqual(newRepetitions, oldRepetitions)) {
        // skip if schema is still loading or repetitions did not changed
        return;
      }
      if (!Array.isArray(oldRepetitions) && !oldRepetitions.schema) {
        // this is the case when no equipment item has been selected or the equipment item is still loading
        // so we can return and wait until a selected equipment item is available
        return;
      }
      let update: Protocol = { ...protocol.value };
      if (Array.isArray(oldRepetitions) && !Array.isArray(newRepetitions)) {
        update = {
          ...update,
          contents: [
            ...update.contents.filter((content) => content.type._id !== contentTypeConfig.value.ref._id),
            {
              type: contentTypeConfig.value.ref,
              data: Object.entries(oldRepetitions[0].data).reduce<ProtocolContentData>((allData, [key, value]) => {
                const keys = key.split('_');
                return { ...allData, [keys[0]]: value };
              }, {}),
            },
          ],
        };
      }
      if (!Array.isArray(oldRepetitions) && Array.isArray(newRepetitions)) {
        update = {
          ...update,
          contents: [
            ...update.contents.filter((content) => content.type._id !== contentTypeConfig.value.ref._id),
            {
              type: contentTypeConfig.value.ref,
              data: Object.entries(oldRepetitions.data).reduce<ProtocolContentData>((allData, [key, value]) => {
                return { ...allData, [`${key}_1`]: value };
              }, {}),
            },
          ],
        };
      }
      if (Array.isArray(oldRepetitions) && Array.isArray(newRepetitions)) {
        if (newRepetitions.length < oldRepetitions.length) {
          const elementNamesToRemove = oldRepetitions
            .slice(newRepetitions.length)
            .flatMap((repetition) => getElements(repetition.schema || []).map((element) => element.name));

          update = {
            ...update,
            contents: [
              ...update.contents.filter((content) => content.type._id !== contentTypeConfig.value.ref._id),
              {
                type: contentTypeConfig.value.ref,
                data: omit(
                  update.contents.find((content) => content.type._id === contentTypeConfig.value.ref._id)?.data || {},
                  elementNamesToRemove,
                ),
              },
            ],
          };
        }
      }
      resetValidation();
      emit('update:protocol', update);
    });

    function updateData(newProtocolContentData: ProtocolContentData | undefined, repetitionIndex?: number): void {
      if (newProtocolContentData === undefined) {
        newProtocolContentData = {};
      }
      const newData =
        repetitionIndex === undefined || !Array.isArray(repetitions.value)
          ? newProtocolContentData
          : repetitions.value.reduce((allData, repetition, index) => {
              return { ...allData, ...(index === repetitionIndex ? newProtocolContentData : repetition.data) };
            }, {});
      const update: Protocol = {
        ...protocol.value,
        contents: [
          ...protocol.value.contents.filter((content) => content.type._id !== contentTypeConfig.value.ref._id),
          {
            type: contentTypeConfig.value.ref,
            data: newData,
          },
        ],
      };
      emit('update:protocol', update);
    }

    const contentHidden = computed(() => {
      return getHiddenProtocolContentBox(userId, protocol).value.includes(contentTypeConfig.value.ref._id);
    });

    function toggleHidden() {
      const hiddenContentTypeIds = getHiddenProtocolContentBox(userId, protocol);
      if (contentHidden.value) {
        const filteredContentTypeIds = hiddenContentTypeIds.value.filter(
          (id) => id !== contentTypeConfig.value.ref._id,
        );
        setHiddenProtocolContentBox(userId, protocol, filteredContentTypeIds);
      } else {
        setHiddenProtocolContentBox(userId, protocol, [...hiddenContentTypeIds.value, contentTypeConfig.value.ref._id]);
      }
    }

    return {
      isLoading,
      protocolContentType,
      updateData,
      instruction,
      repetitions,
      links,
      onValidation,
      isValid,
      peerDependencyContentData,
      previousData,
      toggleHidden,
      contentHidden,
      peerDependencySchema,
    };
  },
});
</script>

<style scoped>
.crossed ::v-deep .listContent {
  text-decoration: line-through;
}
</style>
