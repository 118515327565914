import { ActionManager, User } from '@anschuetz-elog/common';
import { useStorage } from '@vueuse/core';
import { throttle } from 'lodash';
import ms from 'ms';
import { defineStore } from 'pinia';
import Vue, { computed, Ref, ref } from 'vue';

import useFeathers from '#/compositions/useFeathers';
import i18n from '#/i18n';
import dynamicConfig from '#/lib/dynamicConfig';
import logger from '#/logger';

import { useWebsocketStore } from './websocket';

const LS_LOGIN_REDIRECT_URL = 'auth:login-redirect-url';
export const LS_AUTH_TOKEN = 'auth:token';
const LS_AUTH_KEY = 'auth:key';
const LS_AUTH_ENCRYPTION_KEY = 'auth:encryption-key';

/** auto logout offset before session timeout for some cleanup tasks e.g. save records as drafts */
const AUTO_LOGOUT_OFFSET = '10s';

export const ONLINE_RECONNECT_STRATEGY = 'onlineReconnect';

type BeforeLogoutHandler = () => void | Promise<void>;
const beforeLogoutHandlers: BeforeLogoutHandler[] = [];

export function addBeforeLogoutHandler(handler: BeforeLogoutHandler): void {
  if (!beforeLogoutHandlers.includes(handler)) {
    // avoid double handlers
    beforeLogoutHandlers.push(handler);
  }
}

export function removeBeforeLogoutHandler(handler: BeforeLogoutHandler): void {
  const index = beforeLogoutHandlers.indexOf(handler);
  if (index !== -1) {
    beforeLogoutHandlers.splice(index, 1);
  }
}

const reAuthenticateAtServer = throttle(async (isConnected: boolean, isAuthenticated: boolean) => {
  if (!isConnected || !isAuthenticated) {
    return;
  }
  try {
    await useFeathers().reAuthenticate(true, ONLINE_RECONNECT_STRATEGY);
  } catch (error) {
    // ignore error as most likely not authenticated
  }
}, 10 * 1000);

export const useAuthStore = defineStore('auth', () => {
  const websocketStore = useWebsocketStore();

  const user = ref<User | null>(null);
  const key: Ref<string | null> = useStorage<string | null>(LS_AUTH_KEY, null);
  const encryptionKey: Ref<string | null> = useStorage<string | null>(LS_AUTH_ENCRYPTION_KEY, null);
  const sessionExpiredObserver = ref<number | null>(null);
  const loginRedirectUrl: Ref<string | null> = useStorage<string | null>(LS_LOGIN_REDIRECT_URL, null);
  const accessToken: Ref<string | null> = useStorage<string | null>(LS_AUTH_TOKEN, null);

  const isAuthenticated = computed(() => !!user.value);

  const userId = computed(() => {
    if (!user.value) {
      throw new Error('Error getting the userId. The user is not set.');
    }
    return user.value._id;
  });

  function setUser(newUser: User | null): void {
    // update abilities whenever user changes
    const ability = ActionManager.getReference().getAbility(newUser, {
      ptcMaster: dynamicConfig('PTC_MASTER_ENVIRONMENT') || false,
    });
    Vue.prototype.$ability.update(ability.rules);
    if (!newUser) {
      user.value = null;
      key.value = null;
      encryptionKey.value = null;
      accessToken.value = null;
      return;
    }
    user.value = newUser;
  }

  function setLoginRedirectUrl(newLoginRedirectUrl: string | null): void {
    loginRedirectUrl.value = newLoginRedirectUrl;
  }

  /**
   * Start a session-expired observer which logs out inactive users.
   */
  function startSessionExpiredObserver(): void {
    // if a session-expired observer is already running stop it to be able to start a new one
    if (sessionExpiredObserver.value !== null) {
      clearInterval(sessionExpiredObserver.value);
    }

    // auto logout after session duration
    sessionExpiredObserver.value = window.setTimeout(
      () => {
        Vue.toasted.error(i18n.t('general.session_timeout') as string, {
          position: 'bottom-center',
          duration: 3000,
        });
        void logout();
      },
      ms(dynamicConfig('SESSION_TIMEOUT')) - ms(AUTO_LOGOUT_OFFSET),
    );
  }

  function stopSessionExpiredObserver(): void {
    if (sessionExpiredObserver.value !== null) {
      clearInterval(sessionExpiredObserver.value);
      sessionExpiredObserver.value = null;
    }
  }

  /**
   * Load user details into store and refresh current user session.
   */
  async function loadSession(): Promise<void> {
    const noSessionBefore = user.value === null; // only init the local cache if no user was set before
    try {
      await useFeathers().reAuthenticate();
    } catch (error) {
      setUser(null);
      return;
    }
    // start session-expired observer
    startSessionExpiredObserver();
    if (noSessionBefore && import.meta.env.VITE_APP_MODE !== 'cloud') {
      void useFeathers().get('localCache').init();
    }

    accessToken.value = await useFeathers().authentication.getAccessToken();
    if (import.meta.env.VITE_APP_MODE !== 'cloud') {
      if (!key.value) {
        await logout();
        throw new Error('Missing user key');
      }
      // we know we are authenticated now so we try to forward the auth token to the server
      const reAuthenticationPromise = reAuthenticateAtServer(websocketStore.isConnected, true);
      if (noSessionBefore) {
        await reAuthenticationPromise;
      }
    }
    // finally load and cache the authenticated user
    setUser(await getUser());
  }

  /**
   * Get the currently logged in user or null if no one is logged in
   */
  async function getUser(): Promise<User | null> {
    try {
      const result = await useFeathers().get('authentication');
      return result.user;
    } catch (error) {
      return null;
    }
  }

  /**
   * Perform login check of username and password and start user session if successful
   * @param username Username to log in
   * @param password Password to log in
   */
  async function login({ userName, password }: { userName: string; password: string }): Promise<boolean> {
    const feathers = useFeathers();
    try {
      const result = await feathers.authenticate({
        strategy: 'gateway',
        username: userName,
        password,
      });
      key.value = result.user?.privateKey;
      encryptionKey.value = result.user?.encryptionPrivateKey;
      void feathers.get('localCache').init();
      await loadSession();
    } catch (error) {
      // If we got an error, show the login page
      logger.error('Error at login', error);
      setUser(null);
      return false;
    }
    return true;
  }

  /**
   * Stop the current user session and log him out.
   */
  async function logout(): Promise<void> {
    await Promise.all(
      beforeLogoutHandlers.map(async (handler) => {
        removeBeforeLogoutHandler(handler);
        try {
          await handler();
        } catch (error) {
          logger.error('Error happens when execute before logout handler: %s', error);
        }
      }),
    );

    await useFeathers().logout();
    stopSessionExpiredObserver();
    setLoginRedirectUrl(null); // clear login redirect url
    // unset user data
    setUser(null);
    Vue.toasted.info(i18n.t('general.logged_out') as string, {
      position: 'bottom-center',
      duration: 3000,
    });
  }

  return {
    accessToken,
    user,
    key,
    encryptionKey,
    loginRedirectUrl,
    isAuthenticated,
    userId,
    setLoginRedirectUrl,
    loadSession,
    getUser,
    login,
    logout,
  };
});
