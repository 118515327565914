// window._env is set by a script (api/v1/dynamic-frontend-config) loaded in index.html

import { ActionManager, FrontendConfig } from '@anschuetz-elog/common';
import Vue, { ref, watch } from 'vue';

import { useAuthStore } from '#/stores/auth';
import { useWebsocketStore } from '#/stores/websocket';

const defaultConfig: FrontendConfig = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ONLINE_ONLY: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXTERNAL_CONFIGURATION: false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SESSION_TIMEOUT: import.meta.env?.VITE_APP_MODE !== 'cloud' ? '15m' : '60m', // default config, see `default.json`
};

const CACHED_CONFIG_LS_KEY = 'elog::dynamicConfig';
function getCachedConfig(): FrontendConfig | undefined {
  const lsItem = localStorage.getItem(CACHED_CONFIG_LS_KEY);
  if (!lsItem) {
    return undefined;
  }
  return JSON.parse(lsItem) as FrontendConfig;
}
function updateCachedConfig(config: FrontendConfig) {
  localStorage.setItem(CACHED_CONFIG_LS_KEY, JSON.stringify(config));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
const config = ref<FrontendConfig>(((window as any)._env as FrontendConfig) || getCachedConfig() || defaultConfig);
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as any).config = config;
updateCachedConfig(config.value);

export default <T extends keyof FrontendConfig>(key: T): FrontendConfig[T] => {
  return config.value[key];
};

export function load(): void {
  const authStore = useAuthStore();
  const websocketStore = useWebsocketStore();

  Vue.use((): void => {
    watch(
      () => websocketStore.isConnected,
      () => {
        if (websocketStore.isConnected) {
          const dynamicConfigScript = document.createElement('script');
          dynamicConfigScript.setAttribute('type', 'text/javascript');
          dynamicConfigScript.setAttribute('src', '/api/v1/dynamic-frontend-config');
          dynamicConfigScript.setAttribute('async', '');
          document.getElementsByTagName('head')[0].append(dynamicConfigScript);
        }
      },
    );
  });

  watch(config, (newConfig, oldConfig) => {
    // PTC master environment flag, update ability
    if (newConfig['PTC_MASTER_ENVIRONMENT'] !== oldConfig['PTC_MASTER_ENVIRONMENT']) {
      const ability = ActionManager.getReference().getAbility(authStore.user, {
        ptcMaster: config.value['PTC_MASTER_ENVIRONMENT'] || false,
      });
      Vue.prototype.$ability.update(ability.rules);
    }
    updateCachedConfig(config.value);
  });
}
