<template>
  <Box title-centered :title="$tc('report.noon_report.dispatch')">
    <div>
      <template v-if="edit">
        <ProtocolEntry>
          <AutoComplete
            :title="$tc('report.noon_report.dispatched_by')"
            :items="dispatchedByOptions"
            :value="dispatchToEdit.dispatchedBy?._id"
            @input="updateDispatchedBy($event.toString())"
          />
        </ProtocolEntry>
        <ProtocolEntry>
          <DateTimeField
            :title="$tc('report.noon_report.dispatch_timestamp')"
            :value="dispatchToEdit.timestamp"
            @input="dispatchToEdit.timestamp = $event || ''"
          />
        </ProtocolEntry>
        <v-card-actions class="actions pt-0">
          <ActionButton class="pa-3" icon="symbol/cancel" @click="cancelEditingDispatch" />
          <v-spacer />
          <ActionButton class="pa-3" icon="symbol/check" :is-loading="saving" @click="saveDispatch" />
        </v-card-actions>
      </template>
      <template v-else-if="noonReportSent">
        <DetailsListEntry
          class="font-weight-bold"
          color="success"
          :title="$tc('report.noon_report.dispatch_status')"
          :content="$tc('report.noon_report.dispatched')"
        />
        <DetailsListEntry :title="$tc('report.noon_report.dispatched_by')" :content="dispatchedByName" />
        <DetailsListEntry :title="$tc('report.noon_report.dispatch_timestamp')" :content="sendTimestamp" />
        <ProtocolEntry>
          <Button class="px-3" :text="$tc('edit')" @click="editDispatch">
            <Icon name="action/edit" color="white" />
            <span class="ml-3">{{ $tc('edit') }}</span>
          </Button>
        </ProtocolEntry>
      </template>
      <template v-else>
        <DetailsListEntry
          class="font-weight-bold"
          color="error"
          :title="$tc('report.noon_report.dispatch_status')"
          :content="$tc('report.noon_report.not_dispatched')"
        />
        <ProtocolEntry>
          <Button :text="$tc('report.noon_report.dispatch')" @click="editDispatch">
            <Icon name="action/dispatch" color="white" />
            <span class="ml-3 white--text">{{ $tc('report.noon_report.dispatch') }}</span>
          </Button>
        </ProtocolEntry>
      </template>
    </div>
  </Box>
</template>

<script lang="ts">
import { getUtcNow, NoonReportDispatch, Protocol, User } from '@anschuetz-elog/common';
import { computed, defineComponent, getCurrentInstance, ref, toRef } from 'vue';

import { AutoCompleteItemObject } from '#/components/AutoComplete.types';
import AutoComplete from '#/components/AutoComplete.vue';
import Button from '#/components/buttons/Button.vue';
import DateTimeField from '#/components/DateTimeField.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';
import Icon from '#/components/Icon.vue';
import ActionButton from '#/components/layout/ActionButton.vue';
import Box from '#/components/layout/Box.vue';
import ProtocolEntry from '#/components/ProtocolEntry.vue';
import useFeathers from '#/compositions/useFeathers';
import useFind from '#/compositions/useFind';
import i18n from '#/i18n';
import { useAuthStore } from '#/stores/auth';
import { dateTimeStr, getUserFullName } from '#/utilities';

export default defineComponent({
  name: 'NoonReportDispatchBox',
  components: {
    ActionButton,
    AutoComplete,
    Box,
    Button,
    DateTimeField,
    DetailsListEntry,
    Icon,
    ProtocolEntry,
  },
  props: {
    noonReportId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const feathers = useFeathers();
    const noonReportId = toRef(props, 'noonReportId');

    const { data: dispatches } = useFind(
      'noon-report-dispatch',
      computed(() => ({ query: { 'noonReport._id': noonReportId.value } })),
    );

    const dispatch = computed(() => dispatches.value[0]);

    const edit = ref<boolean>(false);
    const saving = ref<boolean>(false);

    const authStore = useAuthStore();
    function createNewDispatch() {
      return new NoonReportDispatch({
        noonReport: Protocol.createRef(noonReportId.value),
        author: User.createRef(authStore.userId),
        dispatchedBy: User.createRef(authStore.userId),
        timestamp: getUtcNow(),
      });
    }
    const vm = getCurrentInstance();
    const dispatchToEdit = ref(createNewDispatch());

    function updateDispatchToEdit(dispatch: NoonReportDispatch) {
      dispatchToEdit.value.noonReport = dispatch.noonReport;
      dispatchToEdit.value.author = dispatch.author;
      dispatchToEdit.value.dispatchedBy = dispatch.dispatchedBy;
      dispatchToEdit.value.timestamp = dispatch.timestamp;
      Object.assign<NoonReportDispatch, Pick<NoonReportDispatch, '_id' | 'createdTimestamp'>>(dispatchToEdit.value, {
        _id: dispatch._id,
        createdTimestamp: dispatch.createdTimestamp,
      });
    }

    const noonReportSent = computed(() => {
      return dispatch.value !== undefined;
    });

    const dispatchedByName = computed(() => {
      if (!dispatch.value) {
        return '';
      }
      return getUserFullName(dispatch.value.dispatchedBy);
    });

    const sendTimestamp = computed(() => {
      if (!dispatch.value) {
        return '';
      }
      return dateTimeStr(dispatch.value.timestamp);
    });

    const editDispatch = () => {
      if (!dispatch.value) {
        dispatchToEdit.value = createNewDispatch();
      } else {
        updateDispatchToEdit(dispatch.value);
      }
      edit.value = true;
    };

    const cancelEditingDispatch = () => {
      if (!dispatch.value) {
        dispatchToEdit.value = createNewDispatch();
      } else {
        updateDispatchToEdit(dispatch.value);
      }
      edit.value = false;
    };

    const saveDispatch = async () => {
      if (!vm) {
        return;
      }
      const service = feathers.service('noon-report-dispatch');
      saving.value = true;
      let result: Promise<NoonReportDispatch>;
      if (dispatch.value) {
        result = service.update(dispatchToEdit.value._id, dispatchToEdit.value);
      } else {
        result = service.create(dispatchToEdit.value);
      }
      try {
        await result;
        vm.proxy.$toasted.success(i18n.tc('report.noon_report.dispatch_successful'));
        edit.value = false;
      } catch (error) {
        vm.proxy.$toasted.error(error as string);
        throw error;
      } finally {
        saving.value = false;
      }
    };

    function updateDispatchedBy(userId: string) {
      dispatchToEdit.value.dispatchedBy = User.createRef(userId);
    }

    const dispatchedByOptions = computed(() => {
      return feathers
        .get('localCache')
        .getUsers()
        .map<AutoCompleteItemObject>((user) => ({
          value: user._id,
          text: user.name,
        }));
    });

    return {
      edit,
      dispatchedByOptions,
      dispatchToEdit,
      updateDispatchedBy,
      cancelEditingDispatch,
      saving,
      saveDispatch,
      noonReportSent,
      dispatchedByName,
      sendTimestamp,
      editDispatch,
    };
  },
});
</script>
