import { defineStore } from 'pinia';
import { ref } from 'vue';

import logger from '@/logger';

export const useServiceWorkerStore = defineStore('service-worker', () => {
  const updating = ref(false);

  function setUpdating(newUpdating: boolean): void {
    logger.info('Setting new updating flag for service worker in store: %s', updating);
    updating.value = newUpdating;
  }

  return {
    updating,
    setUpdating,
  };
});
