<template>
  <div class="d-flex flex-column ma-auto pa-4">
    <div class="mx-auto mb-2 text-center gray_first--text">{{ $t('service_worker.updating') }}</div>
    <v-progress-circular class="mx-auto gray_first--text" indeterminate size="64" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'UpdateBar',
});
</script>
