import { SystemStatus } from '@anschuetz-elog/common';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { Debug } from '@/lib';

const debug = Debug('store:systemStatus');

export interface SystemStatusState {
  systemStatus: SystemStatus | null;
}

export const useSystemStatusStore = defineStore('system-status', () => {
  const systemStatus = ref<SystemStatus | null>(null);

  function updateSystemStatus(newSystemStatus: SystemStatus) {
    debug('updateSystemStatus', newSystemStatus);
    systemStatus.value = newSystemStatus;
  }

  return {
    systemStatus,
    updateSystemStatus,
  };
});
