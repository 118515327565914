<template>
  <Box class="d-flex flex-column" title-centered :title="$tc('protocol.automatic_entries.title')">
    <v-container fluid class="detailsContainer">
      <v-row no-gutters justify="start" class="detailsContainerRow">
        <DetailsListEntry :title="$tc('vessel.vessel_name')" :content="vesselName" />
        <DetailsListEntry :title="$tc('voyage.number')" :content="voyageNumber" />
        <DetailsListEntry
          :title="$tc('report.noon_report.estimated_time_of_arrival')"
          :content="estimatedTimeOfArrival"
        />
        <DetailsListEntry :title="$tc('protocol.creation_time_with_timezone')" :content="createdDateTimeStr" />
        <template v-if="edit">
          <FormEntry class="flex flex-col">
            <DateTimeField
              v-model="syncedTimeString"
              :title="
                $tc('protocol.time_of_event_with_timezone', undefined, {
                  timezone: smtHandlingActive ? $tc('protocol.ships_mean_time_acronym') : $tc('protocol.utc_acronym'),
                })
              "
              :title-hover="
                smtHandlingActive
                  ? $tc('protocol.time_of_event_with_timezone', undefined, {
                      timezone: $tc('protocol.ships_mean_time'),
                    })
                  : undefined
              "
              class="timestamp"
              :mark="!isElementValid('timestamp_required')"
              @invalid="onValidation('timestamp', !$event)"
            />
            <span class="text-warning text-xs my-1 font-medium">{{ $tc('adjust_manually') }}</span>
          </FormEntry>
          <DetailsListEntry
            v-if="smtHandlingActive"
            :title="$tc('protocol.time_of_event_with_timezone', undefined, { timezone: $tc('protocol.utc_acronym') })"
            :content="utcEventTimeString"
          />
          <FormEntry
            v-if="!isClientTimeInSyncWithGateway"
            class="bg-warning_bg border-2 rounded border-warning p-2 space-y-2"
          >
            <div class="text-center text-warning">
              {{ $tc('protocol.client_and_gateway_time_out_of_sync') }}
            </div>
          </FormEntry>
          <FormEntry
            v-if="retrievingNavData"
            class="bg-warning_bg border-2 rounded border-warning p-2 gap-2 flex justify-center items-center"
            data-test-id="retrieving-nav-data-progress"
          >
            <v-progress-circular color="warning" indeterminate size="24" />
            <div class="text-warning">{{ $tc('protocol.retrieving_nav_data') }}</div>
          </FormEntry>
          <FormEntry v-else-if="navDataOutOfSync" class="bg-warning_bg border-2 rounded border-warning p-2 space-y-2">
            <div class="text-center text-warning">
              {{ $tc('protocol.nav_data_out_of_sync') }}
            </div>
            <Button custom-class="!font-600" color="warning" @click="retrieveAndHandleNavData(false)">
              {{ $t('protocol.update_nav_data_by_time') }}
            </Button>
          </FormEntry>
        </template>
        <template v-else>
          <DetailsListEntry
            :title="
              $tc('protocol.time_of_event_with_timezone', undefined, {
                timezone: smtHandlingActive ? $tc('protocol.ships_mean_time_acronym') : $tc('protocol.utc_acronym'),
              })
            "
            :title-hover="
              smtHandlingActive
                ? $tc('protocol.time_of_event_with_timezone', undefined, { timezone: $tc('protocol.ships_mean_time') })
                : undefined
            "
            data-test="smt-timestamp"
            :content="shipsMeanTimeEventTimeString"
          />
          <DetailsListEntry
            v-if="smtHandlingActive"
            :title="$tc('protocol.time_of_event_with_timezone', undefined, { timezone: $tc('protocol.utc_acronym') })"
            data-test="utc-timestamp"
            :content="utcEventTimeString"
          />
        </template>
        <PositionField
          v-model="syncedPosition"
          :edit="edit"
          :mark-as-unknown="isPositionUnknown"
          @invalid="onValidation('position', !$event)"
        />
        <FormEntry v-if="edit && positionNotRetrieved" class="bg-warning_bg border-2 rounded border-warning p-1">
          <div class="text-center text-warning text-xs font-medium">
            {{ $tc('protocol.position_not_available') }}
          </div>
        </FormEntry>
        <SogCogField v-model="syncedSogCog" :edit="edit" @invalid="onValidation('sogCog', !$event)" />
        <FormEntry v-if="edit && sogCogNotRetrieved" class="bg-warning_bg border-2 rounded border-warning p-1">
          <div class="text-center text-warning text-xs font-medium">
            {{ $tc('protocol.sog_cog_not_available') }}
          </div>
        </FormEntry>
        <template v-if="!noReasonForChange">
          <ProtocolEntry v-if="edit || deletion">
            <TextField
              v-model="syncedReasonForChange"
              class="primaryBackground"
              :title="$tc('protocol.reason_for_change')"
              :mark="!isElementValid('reasonForChange')"
            />
          </ProtocolEntry>
          <DetailsListEntry v-else :title="$tc('protocol.reason_for_change')" :content="syncedReasonForChange" />
        </template>
      </v-row>
    </v-container>
  </Box>
</template>

<script lang="ts">
import { isSystemUser, logger, Protocol, VesselParticulars } from '@anschuetz-elog/common';
import { clone } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';
import { computed, defineComponent, getCurrentInstance, onMounted, PropType, ref, toRef } from 'vue';

import Button from '#/components/buttons/Button.vue';
import DateTimeField from '#/components/DateTimeField.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';
import FormEntry from '#/components/FormEntry.vue';
import Box from '#/components/layout/Box.vue';
import PositionField from '#/components/PositionField.vue';
import ProtocolEntry from '#/components/ProtocolEntry.vue';
import SogCogField from '#/components/SogCogField.vue';
import TextField from '#/components/TextField.vue';
import useFeathers from '#/compositions/useFeathers';
import useFind from '#/compositions/useFind';
import useGet from '#/compositions/useGet';
import useValidation from '#/compositions/useValidation';
import { useVesselParticulars } from '#/compositions/useVesselParticulars';
import i18n from '#/i18n';
import { getValidVoyage } from '#/lib/voyages';
import { useClientDeviceStore } from '#/stores/clientDevice';
import { dateTimeStr } from '#/utilities';

type SyncCache = Partial<Pick<Protocol, 'position' | 'timestamp' | 'sogCog'>> | null;

export default defineComponent({
  name: 'AutomaticEntriesContentBox',
  components: {
    Box,
    Button,
    DateTimeField,
    DetailsListEntry,
    PositionField,
    ProtocolEntry,
    SogCogField,
    TextField,
    FormEntry,
  },
  props: {
    timestamp: {
      type: [String, Date, Number] as PropType<Protocol['timestamp'] | undefined>,
      default: undefined,
    },
    position: {
      type: String as PropType<Protocol['position']>,
      default: undefined,
    },
    sogCog: {
      type: String as PropType<Protocol['sogCog']>,
      default: undefined,
    },
    reasonForChange: {
      type: String as PropType<Protocol['reasonForChange']>,
      default: undefined,
    },
    createdTimestamp: {
      type: [String, Date, Number] as PropType<Protocol['createdTimestamp']>,
      required: true,
    },
    noReasonForChange: {
      type: Boolean,
    },
    author: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    create: {
      type: Boolean,
    },
    deletion: {
      type: Boolean,
    },
    syncCache: {
      type: Object as PropType<SyncCache>,
      default: null,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:timestamp': (_value: Protocol['timestamp'] | undefined): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:position': (_value: Protocol['position']): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:sogCog': (_value: Protocol['sogCog']): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:reasonForChange': (_value: Protocol['reasonForChange']): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:syncCache': (_value: SyncCache): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean): boolean => true,
  },
  setup(props, { emit }) {
    const feathers = useFeathers();
    const clientDeviceStore = useClientDeviceStore();

    const smtHandlingActive = !!import.meta.env.VITE_SMT_HANDLING_ENABLED;

    const author = toRef(props, 'author');
    const create = toRef(props, 'create');
    const timestamp = toRef(props, 'timestamp');
    const position = toRef(props, 'position');
    const sogCog = toRef(props, 'sogCog');
    const reasonForChange = toRef(props, 'reasonForChange');
    const createdTimestamp = toRef(props, 'createdTimestamp');

    const { data: vesselParticulars } = useVesselParticulars();
    const { data: voyages } = useFind('voyage');
    const { data: clientDevice, isLoading: loadingClientDevice } = useGet(
      'clientDevice',
      computed(() => clientDeviceStore.clientDeviceId),
    );
    const retrievingNavData = ref<boolean>(false);
    const currentNavDataRetrievalCall = ref<string>();
    const syncCache = toRef(props, 'syncCache');

    function startNavDataRetrieval() {
      currentNavDataRetrievalCall.value = uuidV4();
      logger.info('Starting nav data retrieval', currentNavDataRetrievalCall.value);
      retrievingNavData.value = true;
      return currentNavDataRetrievalCall.value;
    }

    function stopNavDataRetrieval() {
      if (currentNavDataRetrievalCall.value) {
        logger.info('Stopping nav data retrieval', currentNavDataRetrievalCall.value);
      }
      currentNavDataRetrievalCall.value = undefined;
      retrievingNavData.value = false;
    }

    function canNavDataRetrievalBeApplied(retrievalKey: string) {
      return retrievalKey === currentNavDataRetrievalCall.value;
    }

    const { onValidation, isElementValid } = useValidation(emit);
    const vm = getCurrentInstance();
    const createdDateTimeStr = computed(() => {
      return dateTimeStr(moment(createdTimestamp.value).utc().format());
    });

    const shipsMeanTimeEventTimeString = computed(() => {
      return dateTimeStr(syncedTimeString.value);
    });

    const utcEventTimeString = computed(() => {
      return dateTimeStr(moment(syncedTimeString.value).utc().format());
    });

    const syncedTimeString = computed({
      get() {
        return timestamp.value;
      },
      set(newTimestamp: string | undefined) {
        onValidation('timestamp_required', newTimestamp !== undefined);
        emit('update:timestamp', newTimestamp);
        void retrieveAndHandleNavData();
      },
    });

    /** by checking clientDevice's timeInSync boolean property if clientDevice is found. (return true while clientDevice loads) */
    const isClientTimeInSyncWithGateway = computed<boolean>(
      () => loadingClientDevice.value || !!clientDevice.value?.timeInSync,
    );

    const syncedPosition = computed({
      get() {
        return position.value;
      },
      set(newPosition: string | undefined) {
        stopNavDataRetrieval();
        emit('update:position', newPosition);
      },
    });

    const syncedSogCog = computed({
      get() {
        return sogCog.value;
      },
      set(newSogCog: string | undefined) {
        stopNavDataRetrieval();
        emit('update:sogCog', newSogCog);
      },
    });

    const syncedReasonForChange = computed({
      get() {
        return reasonForChange.value;
      },
      set(newReasonForChange: string | undefined) {
        emit('update:reasonForChange', newReasonForChange);
      },
    });

    onMounted(() => {
      if (create.value) {
        emit('update:syncCache', {
          ...syncCache.value,
        });
        void retrieveAndHandleNavData(true);
      } else {
        emit('update:syncCache', {
          timestamp: syncedTimeString.value,
          position: syncedPosition.value,
          sogCog: syncedSogCog.value,
        });
      }
    });

    const isPositionUnknown = computed(() => {
      try {
        const auth = feathers.get('localCache').getUserById(author.value);
        return auth !== undefined && isSystemUser(auth) && syncedPosition.value === undefined;
      } catch (error) {
        return false;
      }
    });

    async function retrieveAndHandleNavData(ignoreConfirmation = false) {
      if (!vm) {
        return;
      }
      stopNavDataRetrieval();
      // check also against invalid fields  because an invalid position results in syncedPosition being `undefined`
      if (!ignoreConfirmation) {
        try {
          await vm.proxy.$dialog.confirm(i18n.tc('protocol.confirm_overwrite_nav_data'));
        } catch (err) {
          // Clicked on cancel, so we do nothing
          return;
        }
      }
      let newSyncCache: SyncCache = {
        timestamp: syncedTimeString.value,
      };
      if (syncedTimeString.value) {
        try {
          const retrievalKey = startNavDataRetrieval();
          const { position, sogCog } = await feathers.service('navigation-data').get(syncedTimeString.value);
          if (!canNavDataRetrievalBeApplied(retrievalKey)) {
            // request has been cancelled or became obsolete
            logger.info('Rejecting result of nav data retrieval', retrievalKey);
            return;
          }
          let newPosition: string | undefined = undefined;
          if (position.value) {
            newPosition = position.value;
            vm.proxy.$toasted.info(i18n.tc('protocol.set_position_success'));
          }
          let newSogCog: string | undefined = undefined;
          if (sogCog.value) {
            newSogCog = sogCog.value;
            vm.proxy.$toasted.info(i18n.tc('protocol.set_sog_cog_success'));
          }
          newSyncCache = {
            ...newSyncCache,
            position: newPosition,
            sogCog: newSogCog,
          };
        } catch (error) {
          vm.proxy.$toasted.error(i18n.tc('protocol.retrieve-navigation-data-error'));
        }
      }
      syncedPosition.value = newSyncCache.position;
      syncedSogCog.value = newSyncCache.sogCog;
      stopNavDataRetrieval();
      emit('update:syncCache', newSyncCache);
    }

    /*
     * get vessel name which was valid at protocol.timestamp
     * vesselParticulars.createdTimestamp <= protocol.timestamp
     *  && there is no vesselParticular with a createdTimestamp closer to protocol.timestamp
     */

    const vesselName = computed(() => {
      if (vesselParticulars.value.length > 0) {
        // sort vesselParticulars by createdTimestamp
        const sortedVesselParticulars = clone(vesselParticulars.value);
        sortedVesselParticulars.sort((a: VesselParticulars, b: VesselParticulars) => {
          if (moment(a.createdTimestamp).isBefore(b.createdTimestamp)) {
            return -1;
          }
          if (moment(a.createdTimestamp).isAfter(b.createdTimestamp)) {
            return 1;
          } else {
            return 0;
          }
        });
        // go through sorted array backwards and return first vesselName which is created before the protocol.timestamp
        for (var i = sortedVesselParticulars.length - 1; i > -1; i--) {
          if (moment(sortedVesselParticulars[i].createdTimestamp).isBefore(syncedTimeString.value)) {
            if (sortedVesselParticulars[i].vesselName) {
              return sortedVesselParticulars[i].vesselName;
            } else {
              // found vesselParticulars does not have a name
              return i18n.tc('emptyMarker');
            }
          }
        }
      }
      // no fitting vesselName found
      return i18n.tc('emptyMarker');
    });

    const validVoyage = computed(() => {
      return getValidVoyage(voyages.value, syncedTimeString.value);
    });

    const voyageNumber = computed(() => {
      if (validVoyage.value && validVoyage.value.voyageNumber) {
        return validVoyage.value.voyageNumber;
      }
      return i18n.tc('emptyMarker');
    });

    const estimatedTimeOfArrival = computed(() => {
      if (validVoyage.value && validVoyage.value.estimatedTimeOfArrival) {
        return dateTimeStr(validVoyage.value.estimatedTimeOfArrival);
      }
      return i18n.tc('emptyMarker');
    });

    const navDataOutOfSync = computed(() => {
      if (!syncCache.value) {
        return false;
      }
      return (
        !moment(syncedTimeString.value).isSame(syncCache.value.timestamp) ||
        (syncCache.value.position !== undefined &&
          syncCache.value.position.localeCompare(syncedPosition.value || '') !== 0) ||
        (syncCache.value.sogCog !== undefined && syncCache.value.sogCog.localeCompare(syncedSogCog.value || '') !== 0)
      );
    });

    const positionNotRetrieved = computed(() => {
      return syncCache.value !== null && syncCache.value.position === undefined;
    });

    const sogCogNotRetrieved = computed(() => {
      return syncCache.value !== null && syncCache.value.sogCog === undefined;
    });

    return {
      vesselName,
      voyageNumber,
      estimatedTimeOfArrival,
      shipsMeanTimeEventTimeString,
      isPositionUnknown,
      retrievingNavData,
      createdDateTimeStr,
      retrieveAndHandleNavData,
      syncedPosition,
      syncedSogCog,
      syncedReasonForChange,
      onValidation,
      isElementValid,
      isClientTimeInSyncWithGateway,
      positionNotRetrieved,
      sogCogNotRetrieved,
      navDataOutOfSync,
      utcEventTimeString,
      syncedTimeString,
      smtHandlingActive,
    };
  },
});
</script>

<style scoped>
.timestamp ::v-deep .v-input {
  background: var(--elog-warning_bg);
}

.primaryBackground ::v-deep .v-input {
  background: var(--v-primary_bg-base);
}

.detailsContainer {
  padding: 5px 0;
}

.detailsContainerRow > * {
  flex: 0 1 100%;
}
</style>
