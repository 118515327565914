<template>
  <div :data-test="dataTest">
    <FormInputElement
      v-for="item of extendedSchema"
      :key="item.key"
      v-model="formValue"
      :element="item.element"
      :parent-value="parentValue"
      :peer-dependency-value="peerDependencyValue"
      :peer-dependency-schema="peerDependencySchema"
      :edit="item.element.type !== undefined && !item.element.disabled && edit"
      :links="links"
      :previous-data="previousData"
      :custom-validations="item.customValidations"
      :schema="schema"
      @valid="onValid(item.element, $event)"
      @protocollink:takeover-data="$emit('protocollink:takeover-data', $event)"
    />
  </div>
</template>

<script lang="ts">
import {
  areElementConditionalsMatching,
  ComponentElement,
  Element,
  Protocol,
  ProtocolContentData,
  Schema,
} from '@anschuetz-elog/common';
import { isEqual, omit } from 'lodash';
import { computed, defineComponent, PropType, toRef, watch } from 'vue';

import useValidation from '#/compositions/useValidation';

import { CustomValidationFunction, ProtocolLinkConfigProp } from './utils';

export default defineComponent({
  name: 'Form',

  // FormInputElement is registered globally in #/install.ts due to recursive component imports
  // components: { FormInputElement },

  props: {
    value: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    schema: {
      type: Array as PropType<Schema>,
      required: true,
    },

    peerDependencySchema: {
      type: Array as PropType<Schema>,
      default: () => [],
    },

    edit: {
      type: Boolean,
    },

    peerDependencyValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    links: {
      type: Array as PropType<ProtocolLinkConfigProp[]>,
      default: () => [],
    },

    customValidations: {
      type: Object as PropType<
        Record<
          Element['_id'],
          Record<
            string,
            | CustomValidationFunction
            | Record<string, CustomValidationFunction | Record<string, CustomValidationFunction>>
          >
        >
      >,
      default: () => ({}),
    },

    dataTest: {
      type: String,
      default: undefined,
    },

    parentSchema: {
      type: Array as PropType<Schema>,
      default: () => [],
    },

    previousData: {
      type: Array as PropType<ProtocolContentData[]>,
      default: () => [],
    },

    parentValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'protocollink:takeover-data': (_linkedProtocol: Protocol) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const schema = toRef(props, 'schema');
    const edit = toRef(props, 'edit');
    const customValidations = toRef(props, 'customValidations');

    const parentValue = toRef(props, 'parentValue');
    const parentSchema = toRef(props, 'parentSchema');
    const peerDependencySchema = toRef(props, 'peerDependencySchema');
    const peerDependencyValue = toRef(props, 'peerDependencyValue');

    const { onValidation, isValid, resetValidation } = useValidation(emit);

    function filterElements(schema: Schema): Schema {
      return schema
        .filter(
          (element) =>
            // filter away ignored elements in read mode
            !(element as Element).ignoreForRecord || edit.value,
        )
        .filter((element) => {
          // filter away elements whose conditional configuration does not match
          if (element.type === undefined) {
            return true;
          }
          return areElementConditionalsMatching(element, schema, value.value, {
            parentSchema: parentSchema.value,
            parentValue: parentValue.value,
            peerDependencySchema: peerDependencySchema.value,
            peerDependencyValue: peerDependencyValue.value,
          });
        });
    }

    const filteredSchema = computed(() => filterElements(schema.value));

    const extendedSchema = computed(() => {
      let componentIndex = 0;
      return filteredSchema.value.map((element) => {
        const key = element.type === undefined ? `comp-${componentIndex++}` : element.name;
        const customValidationsOfElement = element.type === undefined ? {} : customValidations.value[element._id];
        return {
          key,
          element,
          customValidations: customValidationsOfElement,
        };
      });
    });

    const formValue = computed<Record<string, unknown>>({
      get() {
        return value.value;
      },
      set(newValue) {
        emit('input', newValue);
      },
    });

    function onValid(element: ComponentElement | Element, valid: boolean) {
      if (element.type === undefined) {
        return;
      }
      onValidation(element._id, valid);
    }

    watch(filteredSchema, (newSchema, oldSchema) => {
      if (isEqual(newSchema, oldSchema)) {
        return;
      }
      const elementIds = newSchema
        .map((element) => (element.type === undefined ? null : element._id))
        .filter((id): id is string => id !== null);
      resetValidation(elementIds);

      const removedElementNames = oldSchema
        .filter((element) => element.type === undefined || !elementIds.includes(element._id))
        .map((element) => (element.type === undefined ? null : element.name))
        .filter((name): name is string => name !== null);
      if (formValue.value !== undefined && removedElementNames.length > 0)
        formValue.value = omit(formValue.value, removedElementNames);
    });

    return { formValue, onValid, isValid, extendedSchema };
  },
});
</script>
